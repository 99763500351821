export const START = 'MW-START';
export const WORK = 'MW-WORK';
export const AUTONOMY = 'MW-AUTONOMY';
export const MOTIVATION = 'MW-MOTIVATION';
export const BELONGING = 'MW-BELONGING';
export const COMPETENCE = 'MW-COMPETENCE';
export const SCORE = 'MW-SCORE';

export function storeData(data) {
  return {
    type: data.actionType,
    payload: data,
  };
}

export function calculateImpact(state, questionMap) {
  let impact = 0;
  const positivePointsMap = { sta: 4, swa: 3, swd: 2, std: 1, '': 0 };
  const negativePointsMap = { sta: 1, swa: 2, swd: 3, std: 4, '': 0 };

  for (let i = 0; i < questionMap.length; i++) {
    const question = questionMap[i];
    if (question.type === 'negative') {
      impact += negativePointsMap[state[question.name]];
    } else {
      impact += positivePointsMap[state[question.name]];
    }
  }
  return impact;
}
