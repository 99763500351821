import React, {
  useState, useEffect, useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import 'react-rangeslider/lib/index.css';
import Slider from 'react-rangeslider';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { storeData, START } from 'ef-calculator/actions';
import BottomNavButtons from 'common/components/BottomNavButtons';

import SliderSound from 'assets/sounds/slider.mp3';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import WavesSound from 'assets/sounds/water-waves.mp3';

const Starts = (props) => {
  const [name, setName] = useState('');
  const [city, setCity] = useState('');
  const [hours, setHours] = useState(3);

  const [play] = useSound(SliderSound);
  const [playNavChange] = useSound(PageChangeSound);
  // TEMP
  // eslint-disable-next-line no-unused-vars
  const [playWaves] = useSound(WavesSound);

  const componentWillUnmount = useRef(false);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      props.storeData({
        city, hours, userName: name, actionType: START,
      });
    }
  }
  // eslint-disable-next-line
	, [city, hours, name])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setCity(props.startData.city || city);
      setHours(props.startData.hours || hours);
      setName((props.location.data || props.startData).userName || name);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const { t } = useTranslation();
  const isNextDisabled = city === '';
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';

  return (
    <>
      <Header disableNext={isNextDisabled} />
      <main className="main">
        <div className="default-wrapper start-page">
          <div className="form-row">
            <h2>
              {isUrduLang
                ? <span dir="rtl">{t('startCityQT', { name })}</span>
                : t('startCityQT', { name: `Hey ${name}` })}
            </h2>

            <div className="form-box">
              <input
                value={city}
                maxLength="40"
                className={`form-control ${city && 'input-focused'}`}
                onChange={(e) => setCity(e.target.value)}
              />
              <span className="input-label">{t('startCityInputLabel')}</span>
            </div>
          </div>
          <div className="form-row">
            <h2>{t('startHourQT')}</h2>
            <Slider
              min={1}
              max={8}
              step={1}
              value={hours}
              tooltip
              reverse={isUrduLang}
              onChangeComplete={() => play()}
              onChange={(value) => setHours(value)}
              labels={{
                1: '1', 2: '2', 3: '3', 4: '4', 5: '5', 6: '6', 7: '7', 8: '8+',
              }}
            />
          </div>
        </div>
      </main>

      <BottomNavButtons
        pageNum="1"
        previous="/ef-calculator"
        isNextDisabled={isNextDisabled}
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
        next="/ef-calculator/transportation"
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ startData: efCalculatorReducer.start });
}

export default connect(mapStateToProp, { storeData })(withRouter(Starts));
