import { createReducer } from '@reduxjs/toolkit';
import { START, WORK, AUTONOMY, BELONGING, COMPETENCE, MOTIVATION, SCORE } from './actions';

const initialState = {
  start: {},
  work: {},
  autonomy: {},
  belonging: {},
  competence: {},
  motivation: {},
  score: {},
};

const mentalWellBeingReducer = createReducer(initialState, {
  [START]: (state, action) => ({ ...state, start: action.payload }),
  [WORK]: (state, action) => ({ ...state, work: action.payload }),
  [AUTONOMY]: (state, action) => ({ ...state, autonomy: action.payload }),
  [BELONGING]: (state, action) => ({ ...state, belonging: action.payload }),
  [COMPETENCE]: (state, action) => ({ ...state, competence: action.payload }),
  [MOTIVATION]: (state, action) => ({ ...state, motivation: action.payload }),
  [SCORE]: (state, action) => ({ ...state, score: action.payload }),
});

export default mentalWellBeingReducer;
