import { Link, withRouter } from 'react-router-dom';
import React, { useEffect } from 'react';
import useSound from 'use-sound';

import Header from 'mental-wellbeing/components/header';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import FinalSound from 'assets/sounds/final.wav';

const Action = () => {
  const [play] = useSound(PageChangeSound);
  const [playFinalSound] = useSound(FinalSound);

  useEffect(() => {
    playFinalSound();
    // eslint-disable-next-line
	}, [playFinalSound])

  return (
    <>
      <Header
        handleNavChange={() => {
        }}
        disableNext={false}
      />

      <main className="main">
        <div className="four-opt-wrapper default-wrapper action-block">
          <h2>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed?</h2>
          <ol>
            <li><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p></li>
            <li>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.
                At vero eoset accusam et justo duo.
              </p>
            </li>
            <li><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam</p></li>
            <li>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut
              </p>
            </li>
            <li>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor
              </p>
            </li>
            <li>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor invidunt ut
              </p>
            </li>
            <li>
              <p>
                Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                tempor
              </p>
            </li>
            <li><p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy </p></li>
          </ol>
        </div>
      </main>
      <div className="footer-buttons">
        <Link to="/mental-well-being/score" className="btn btn-primary" onClick={() => play()}>
          Back
        </Link>
        <strong className="step-count">8/8</strong>
      </div>
    </>
  );
};

export default withRouter(Action);
