import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import NumericInput from 'common/components/NumericInput';
import { storeData, ELECTRICITY } from 'ef-calculator/actions';
import BottomNavButtons from 'common/components/BottomNavButtons';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'ef-calculator/pages/electricity/index.scss';
import ButtonSound from 'assets/sounds/button.mp3';

const Electricity = (props) => {
  const [space, setSpace] = useState('');
  const [isDanger, setIsDanger] = useState(false);
  const [peopleCount, setPeopleCount] = useState('');

  const [playNavChange] = useSound(PageChangeSound);
  const [playButtonSound] = useSound(ButtonSound);

  const { t } = useTranslation();
  const isSharedSpace = space === 'shared';
  const componentWillUnmount = useRef(false);

  useEffect(() => () => { componentWillUnmount.current = true; }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      props.storeData({
        actionType: ELECTRICITY,
        electricInfo: { space, peopleCount: parseInt(peopleCount) },
        applianceCount: props.electricityData.applianceCount || {},
      });
    }
  }
  // eslint-disable-next-line
	, [space, peopleCount, props.electricityData])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      setSpace(props.electricityData.electricInfo.space || space);
      setPeopleCount(props.electricityData.electricInfo.peopleCount || peopleCount);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isNextDisabled = () => {
    if (isSharedSpace && peopleCount < 2) {
      return true;
    }
    return !(space && (isSharedSpace ? peopleCount : true));
  };

  const handleInputChange = (e) => {
    if (e.target.validity.valid && parseInt(e.target.value) > 0) {
      setPeopleCount(parseInt(e.target.value));
      setIsDanger(e.target.value < 2);
    } else if (e.target.value === '') {
      setPeopleCount(e.target.value);
      setIsDanger(false);
    }
  };

  return (
    <>
      <Header disableNext={isNextDisabled()} />

      <main className="main">
        <div className="default-wrapper electricity-page">
          <div className="form-row">
            <h2>{t('electricityConsumptionQT')}</h2>
            <ul className="buttons-list expanded">
              {/*  TEMPORARY DISABLED  */}
              {/* <li> */}
              {/*    <button className={`btn-secondary ${(space === "default") && "active"}`} */}
              {/*            value="default" */}
              {/*            onClick={(e) => this.setSpace(e)}> */}
              {/*        Use the default option */}
              {/* </button> */}
              {/* </li> */}
              <li>
                <button
                  className={`btn-secondary ${(space === 'personal') && 'active'}`}
                  value="personal"
                  onClick={(e) => {
                    playButtonSound();
                    setSpace(e.target.value);
                  }}
                >
                  {t('electricityConsumptionPersonal')}
                </button>
              </li>
              <li>
                <button
                  className={`btn-secondary ${isSharedSpace && 'active'}`}
                  value="shared"
                  onClick={(e) => {
                    playButtonSound();
                    setSpace(e.target.value);
                  }}
                >
                  {t('electricityConsumptionShared')}
                </button>
              </li>
            </ul>
            {
              isSharedSpace
              && (
                <NumericInput
                  value={peopleCount}
                  maxLength="3"
                  text={t('electricityConsumptionPeopleCount')}
                  handleInputChange={(e) => handleInputChange(e)}
                  errorText={t('electricitySharedHint')}
                  isDanger={isDanger}
                />
              )
            }
          </div>
        </div>
      </main>

      <BottomNavButtons
        pageNum="4"
        previous="/ef-calculator/paper"
        isNextDisabled={isNextDisabled()}
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
        next="/ef-calculator/electricity-appliances"
      />
    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ electricityData: efCalculatorReducer.electricity });
}

export default connect(mapStateToProp, { storeData })(withRouter(Electricity));
