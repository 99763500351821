/* eslint-disable prefer-const */
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import i18next from 'i18next';

import PaginationDots from 'common/components/PaginationDots';
import OptionSection from 'ergonomics/components/OptionSection';
import AnswerSection from 'ergonomics/components/AnswerSection';

import Home from 'ergonomics/assets/images/bg-home.svg';
import cursor from 'ergonomics/assets/images/curson.svg';
import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import Office from 'ergonomics/assets/images/bg-office.svg';

const BaseComponent = (props) => {
  let { afterClicked, beforeClicked, UserImg, CorrectAssetsMap, PCImg,
    userClassName, pcClassName, currentPage, nextLink, current } = props;

  const [isTargetClicked, setIsTargetClicked] = useState(false);
  const [answer, setAnswer] = useState('');
  const { t } = useTranslation();
  const langDir = i18next.dir();

  const bgImage = (props.workData.workFrom === 'home') ? Home : Office;
  let issueClassName = isTargetClicked ? afterClicked : beforeClicked;

  if (answer === 'correct') {
    issueClassName = '';
    UserImg = CorrectAssetsMap.userCorrectImg ? CorrectAssetsMap.userCorrectImg : UserImg;
    userClassName = CorrectAssetsMap.userClassName ? CorrectAssetsMap.userClassName : userClassName;
    pcClassName = CorrectAssetsMap.pcClassName ? CorrectAssetsMap.pcClassName : pcClassName;
    PCImg = CorrectAssetsMap.correctTableImg ? CorrectAssetsMap.correctTableImg : PCImg;
  }

  const getHintText = () => {
    let keysMap = { hintText1: 'ergHint1', hint: `erg${current}Hint`, hintText2: 'ergHint2' };
    if (current === 'Feet') {
      keysMap = { hintText1: 'ergFeetHint1', hint: 'ergFeetHint', hintText2: 'ergFeetHint2' };
    } else if (current === 'Neck') {
      keysMap = { hintText1: 'ergNeckHint1', hint: 'ergNeckHint', hintText2: 'ergNeckHint2' };
    }

    return (
      <p className="help-text">
        { t(keysMap.hintText1) }
        <span style={{ textDecorationLine: 'underline', textUnderlineOffset: '0.4em' }}>
          { t(keysMap.hint) }
        </span>{ t(keysMap.hintText2) }
      </p>
    );
  };

  return (
    <div className="row">
      <div className="col-image">
        <div className="image-wrap ergonomics">
          <span
            className={`fix-style ${issueClassName}`}
            onClick={() => setIsTargetClicked(true)}
          >
            <img alt="cursor" src={cursor} />
          </span>
          <img className={userClassName} alt="user position" src={UserImg} />
          <img className={pcClassName} alt="pc position" src={PCImg} />
          <img className="bg-position" alt="work place" src={bgImage} />
        </div>
      </div>
      <div className={`col-info ${langDir}`}>
        <h2 dir={langDir} className={`heading-h2 ${langDir}`}>
          { t(`erg${current}`) }
        </h2>
        { getHintText() }
        {
            isTargetClicked
            && (
              <OptionSection
                current={current}
                answer={answer}
                clickHandler={(optName) => setAnswer(optName)}
              />
            )
        }
        {
            answer
            && <AnswerSection answer={answer} nextLink={nextLink} current={current} />
        }
        <PaginationDots currentPage={currentPage} total={5} />
      </div>
    </div>
  );
};

function mapStateToProp({ ergonomicsReducer }) {
  return ({ workData: ergonomicsReducer.work });
}

export default connect(mapStateToProp)(withRouter(BaseComponent));
