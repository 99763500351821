import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { AUTONOMY } from 'mental-wellbeing/actions';
import BaseComponent from 'mental-wellbeing/components/BaseComponent';

const Autonomy = ({ autonomyData }) => {
  const initialState = {
    workDecisionControl: '',
    necessitiesAccess: '',
    obligated: '',
    leadTrust: '',
    suggestions: '',
  };
  const allQuestionMap = [
    {
      text: 'I mostly have control over work decisions that concern me',
      type: 'positive',
      name: 'workDecisionControl',
      currentPage: 1,
    },
    {
      text: 'I have access to all the necessities required to do my job adequately',
      type: 'positive',
      name: 'necessitiesAccess',
      currentPage: 1,
    },
    {
      text: 'I am obligated to do things that I believe ought to be done differently',
      type: 'negative',
      name: 'obligated',
      currentPage: 2,
    },
    {
      text: 'My team lead or manager trusts me with how I carry out my tasks',
      type: 'positive',
      name: 'leadTrust',
      currentPage: 2,
    },
    {
      text: 'My team lead is open to my suggestions regarding my roles and responsibilities',
      type: 'positive',
      name: 'suggestions',
      currentPage: 3,
    },
  ];

  return (
    <BaseComponent
      maxPage={3}
      type={AUTONOMY}
      current="autonomy"
      sectionPage={3}
      next="motivation"
      previous="work"
      savedData={autonomyData}
      initialState={initialState}
      allQuestionMap={allQuestionMap}
    />
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({ autonomyData: mentalWellBeingReducer.autonomy });
}

export default connect(mapStateToProp)(withRouter(Autonomy));
