import React from 'react';

const EmbeddedLink = (props) => (
  <div className="dropdown-menu">
    <div className="dropdown-content">
      <div>
        {props.link}
      </div>
    </div>
  </div>
);

export default EmbeddedLink;
