/* eslint-disable no-case-declarations */
import React, { useRef, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { storeData, PAPER } from 'ef-calculator/actions';
import NumericInput from 'common/components/NumericInput';
import BottomNavButtons from 'common/components/BottomNavButtons';
import { calculatePaperCarbonEmission } from 'ef-calculator/utils/helper';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonSound from 'assets/sounds/button.mp3';

const reducer = (state, action) => {
  switch (action.type) {
    case 'setPageCount':
      const pc = action.isValid ? action.pageCount : state.pagesCount;
      return { ...state, pagesCount: pc, duplex: (pc === '') ? '' : state.duplex };
    case 'setDuplex':
      return { ...state, duplex: action.duplex };

    case 'update': return { ...state, ...action.payload };
    default: throw new Error('Unexpected action');
  }
};

const Paper = (props) => {
  const componentWillUnmount = useRef(false);
  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, { pagesCount: '', duplex: '' });
  const duplexValues = ['yes', 'no', 'sometimes'];
  const [playButtonSound] = useSound(ButtonSound);
  const [playNavChange] = useSound(PageChangeSound);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      const impact = calculatePaperCarbonEmission(state.pagesCount, state.duplex);
      // eslint-disable-next-line no-console
      console.log(`paper:  ${impact}`); // temp
      props.storeData({ ...state, impact, actionType: PAPER });
    }
  }
  // eslint-disable-next-line
	, [state])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({
        type: 'update',
        payload: {
          pagesCount: props.paperData.pagesCount || state.pagesCount,
          duplex: props.paperData.duplex || state.duplex,
        },
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isNextDisabled = () => {
    const pageCount = parseInt(state.pagesCount);
    if (pageCount === 0) {
      return false;
    }
    return !(pageCount && state.duplex);
  };

  return (
    <>
      <Header disableNext={isNextDisabled()} />

      <main className="main">
        <div className="default-wrapper">
          <div className="form-row">
            <h2>{t('paperPageCountQT')}</h2>
            <NumericInput
              maxLength="4"
              value={state.pagesCount}
              text={t('paperPageCountInputLabel')}
              handleInputChange={(e) => dispatch({
                type: 'setPageCount', isValid: e.target.validity.valid, pageCount: e.target.value,
              })}
            />
          </div>
          {
            state.pagesCount > 0
            && (
              <div className="form-row">
                <h2>{t('paperDuplexQT')}</h2>
                <ul className="buttons-list">
                  {
                    duplexValues.map((dp) => (
                      <li key={dp}>
                        <button
                          value={dp}
                          className={`btn-secondary ${(state.duplex === dp) && 'active'}`}
                          onClick={(e) => {
                            playButtonSound();
                            dispatch({ type: 'setDuplex', duplex: e.target.value });
                          }}
                        >
                          {t(dp)}
                        </button>
                      </li>
                    ))
                  }
                </ul>
              </div>
            )
          }
        </div>
      </main>

      <BottomNavButtons
        pageNum="3"
        next="/ef-calculator/electricity"
        isNextDisabled={isNextDisabled()}
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
        previous="/ef-calculator/transportation"
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ paperData: efCalculatorReducer.paper });
}

export default connect(mapStateToProp, { storeData })(withRouter(Paper));
