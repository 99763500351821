import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';

import 'components/button';
import NotFound from 'common/pages/404';
import Start from 'ef-calculator/pages/start';
import Water from 'ef-calculator/pages/water';
import Paper from 'ef-calculator/pages/paper';
import Plastic from 'ef-calculator/pages/plastic';
import Action from 'ef-calculator/pages/take-action';
import FootPrint from 'ef-calculator/pages/footprint';
import GetStarted from 'common/pages/get-started/index';
import WaterBreak from 'ef-calculator/pages/water/break';
import WaterWaste from 'ef-calculator/pages/water/waste';
import Electricity from 'ef-calculator/pages/electricity';
import Transportation from 'ef-calculator/pages/transportation';
import Logo from 'ef-calculator/assets/images/logo-ef-calculator.svg';
import ElectricityAppliances from 'ef-calculator/pages/electricity/appliances';

const EFCalculator = () => {
  const { t } = useTranslation();
  return (
    <Router>
      <Switch>
        <Route path="/ef-calculator" exact>
          <GetStarted
            title={t('termsTitle')}
            next="/ef-calculator/start"
            logo={Logo}
          />
        </Route>
        <Route>
          <div className="wrapper">
            <Switch>
              <Route path="/ef-calculator/start" component={Start} />
              <Route path="/ef-calculator/transportation" component={Transportation} />
              <Route path="/ef-calculator/paper" component={Paper} />
              <Route path="/ef-calculator/electricity" component={Electricity} />
              <Route path="/ef-calculator/electricity-appliances" component={ElectricityAppliances} />
              <Route path="/ef-calculator/plastic" component={Plastic} />
              <Route path="/ef-calculator/water" component={Water} />
              <Route path="/ef-calculator/water-break" component={WaterBreak} />
              <Route path="/ef-calculator/water-waste" component={WaterWaste} />
              <Route path="/ef-calculator/footprint" component={FootPrint} />
              <Route path="/ef-calculator/take-action" component={Action} />
              <Route path="/ef-calculator/*" component={NotFound} />
            </Switch>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default withRouter(EFCalculator);
