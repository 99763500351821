import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import ergonomicsReducer from 'ergonomics/reducers';
import efCalculatorReducer from 'ef-calculator/reducers';
import mentalWellBeingReducer from 'mental-wellbeing/reducers';

const reducer = combineReducers({
  efCalculatorReducer, mentalWellBeingReducer, ergonomicsReducer,
});

const store = configureStore({
  reducer,
});

export default store;
