import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import BankSmsImg from 'go-phishing/assets/images/bank-sms.png';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';

const BankSms = () => {
  const [answer, setAnswer] = useState('');
  const correctAnswer = 'legitimate';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';
  const answerFeedback = {
    correct: 'This is an automated message sent by the official Marco Bank number. '
            + 'Make sure that you’re not being asked to confirm your credit card details or other personal '
            + 'information that someone may use to hack your accounts. Since this message doesn’t seem to be '
            + 'asking for any sensitive information, it’s legitimate.',
    wrong: 'This is an automated message sent by the official Marco Bank number. Make sure that you’re not '
            + 'being asked to confirm your credit card details or other personal information that someone may use '
            + 'to hack your accounts. Since this message doesn’t seem to be asking for any sensitive information,'
            + ' it’s legitimate.',
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                <img alt="Phishing Screen" src={BankSmsImg} />
              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={3}
            next="consent-phishing"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Your bank’s concerned."
            helpText="That’s a first."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(BankSms);
