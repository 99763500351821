/* eslint-disable */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import detector from 'i18next-browser-languagedetector';

i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    lang:"en",
    fallbackLng: "en",
    react: {
       wait: true
    },
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    debug: false,
    interpolation: {
        format: function(value, format, lng) {
            if (format === 'uppercase') {
                return value.toUpperCase();
            } else if (format === 'titlecase') {
                value = value.trim();
                if (value === "") {
                    return "";
                }
                value = value.toLowerCase().split(' ');
                return value.map((word) => { return word.replace(word[0], word[0].toUpperCase()); }).join(' ');
            }
        }
    }
  });

export default i18n;
