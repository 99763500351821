import React from 'react';

const RecipientBox = (props) => {
  const dateData = new Date().toDateString().split(' ');
  const timeData = new Date().toLocaleString(
    'en-US', { hour: 'numeric', minute: 'numeric', hour12: true },
  );

  return (
    <div className="dropdown-menu">
      <div className="dropdown-content email-info-box">
        <div className="sender-info">
          <div className="info-row">
            <div className="label-left">From:</div>
            <div className="label-right">{props.senderInfo.name} &#60;{props.senderInfo.email}&#62;</div>
          </div>
          <div className="info-row">
            <div className="label-left">To:</div>
            <div className="label-right">You ‎&#60;{props.senderInfo.userEmail}&#62;‎</div>
          </div>
          <div className="info-row">
            <div className="label-left">Date:</div>
            <div className="label-right">{dateData[0]}, {dateData[1]} {dateData[2]}, {timeData}</div>
          </div>
          <div className="info-row">
            <div className="label-left">Subject:</div>
            <div className="label-right">{props.senderInfo.subject}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecipientBox;
