import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { WATER, storeData } from 'ef-calculator/actions';
import { calculateWaterConsumption } from 'ef-calculator/utils/helper';

import BottomNavButtons from 'common/components/BottomNavButtons';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonSound from 'assets/sounds/button.mp3';

const reducer = (state, action) => {
  switch (action.type) {
    case 'NextPage': return { ...state, page: state.page + 1 };
    case 'PreviousPage': return { ...state, page: state.page - 1 };
    case 'updateState': return { ...state, [action.e.target.name]: action.e.target.value };
    case 'update': return { ...state, ...action.payload };

    default: throw new Error('Unexpected action');
  }
};

const Water = (props) => {
  const [playNavChange] = useSound(PageChangeSound);
  const [playButtonSound] = useSound(ButtonSound);

  const { t } = useTranslation();
  const componentWillUnmount = useRef(false);
  const [state, dispatch] = useReducer(reducer, { faucetTime: '', lowFaucet: '', LFToilets: '', page: 1 });
  const allQuestionMap = [
    {
      t_name: 'waterTapTimeQT',
      name: 'faucetTime',
      page: 1,
      options: [{ t_name: 'waterU5min', value: 'u5' }, { t_name: 'waterB5_10min', value: 'b5_10' },
        { t_name: 'water15min', value: 'o15' }],
    },
    {
      t_name: 'waterLowFlowTapQT',
      name: 'lowFaucet',
      page: 1,
      options: [{ t_name: 'yes', value: 'yes' }, { t_name: 'no', value: 'no' },
        { t_name: 'notSure', value: 'notSure' }],
    },
    {
      t_name: 'waterLowFlowToiletQT',
      name: 'LFToilets',
      page: 2,
      options: [{ t_name: 'yes', value: 'yes' }, { t_name: 'no', value: 'no' },
        { t_name: 'notSure', value: 'notSure' }],
    },
  ];
  const questionMap = allQuestionMap.filter((q) => q.page === state.page);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      const impact = calculateWaterConsumption(state, allQuestionMap);
      // eslint-disable-next-line no-console
      console.log(`water:  ${impact}`); // temp
      props.storeData({ waterInfo: state, impactInfo: impact, shopInfo: props.waterData.shopInfo, actionType: WATER });
    }
  }
  // eslint-disable-next-line
	, [state, props.waterData.shopInfo])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({ type: 'update', payload: props.waterData.waterInfo });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isNextDisabled = () => {
    for (let i = 0; i < questionMap.length; i++) {
      if (!state[questionMap[i].name]) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <Header disableNext />

      <main className="main">
        <div className="default-wrapper">
          <div className="form-row">

            {questionMap.map((q) => (
              <div className="form-row" key={q.name}>
                <h2>{t(q.t_name)}</h2>
                <ul className="buttons-list">
                  {q.options.map((opt) => (
                    <li key={opt.value}>
                      <button
                        name={q.name}
                        value={opt.value}
                        className={`btn-secondary ${(state[q.name] === opt.value) && 'active'}`}
                        onClick={(e) => {
                          playButtonSound();
                          dispatch({ type: 'updateState', e });
                        }}
                      >
                        {t(opt.t_name)}
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            ))}

          </div>
        </div>
      </main>

      <BottomNavButtons
        pageNum="6"
        isNextDisabled={isNextDisabled()}
        onNextClick={() => { playNavChange(); dispatch({ type: 'NextPage' }); }}
        onBackClick={() => { playNavChange(); dispatch({ type: 'PreviousPage' }); }}
        previous={`/ef-calculator/${state.page === 1 ? 'plastic' : 'water'}`}
        next={`/ef-calculator/${state.page === 2 ? 'water-break' : 'water'}`}
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ waterData: efCalculatorReducer.water });
}

export default connect(mapStateToProp, { storeData })(withRouter(Water));
