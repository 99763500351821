import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';
import RecipientBox from 'go-phishing/components/RecipientBox';
import BadReplyImg from 'go-phishing/assets/images/bad-reply.svg';
import WarningSign from 'go-phishing/assets/images/warning-sign.svg';

import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'ergonomics/assets/scss/components/_info-box.scss';

const BadReply = (props) => {
  const [answer, setAnswer] = useState('');
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);

  const correctAnswer = 'phishing';
  const { data } = props.location;
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const answerFeedback = {
    correct: 'Even if the email is from your actual bank, no professional employee would ask you for '
          + 'sensitive information such as your credit card number, your personal details, and your CVC '
          + 'over email! Always inform the bank if you receive messages such as these.',
    wrong: 'Even if the email is from your actual bank, no professional employee would ask you for '
          + 'sensitive information such as your credit card number, your personal details, and your CVC '
          + 'over email! Always inform the bank if you receive messages such as these.',
  };
  const senderInfo = {
    name: 'Hasib Bank',
    email: 'Employee@Hasibbank.com',
    subject: 'URGENT: Credit Card Expiry',
    userEmail: data && data.userEmail,
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                {
                    answer === 'legitimate'
                    && (
                      <div className="warning-sign reply">
                        <img alt="Warning" src={WarningSign} />
                      </div>
                    )
                }
                <img alt="Bad Reply Screen" src={BadReplyImg} />
                <div
                  className="target-link sender"
                  onMouseEnter={() => setShowSenderInfoBox(true)}
                  onMouseLeave={() => setShowSenderInfoBox(false)}
                >
                  {
                      showSenderInfoBox
                      && <RecipientBox senderInfo={senderInfo} />
                  }
                </div>
              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={5}
            next="outside-company"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Money isn’t everything."
            helpText="But it’s still worth saving from theft."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(BadReply);
