import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { storeData, WATER, WATER_WASTE } from 'ef-calculator/actions';
import { waterWasteQuestionMap } from 'ef-calculator/utils/constants';
import { calculateWaterWasteImpact } from 'ef-calculator/utils/helper';

import BottomNavButtons from 'common/components/BottomNavButtons';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonSound from 'assets/sounds/button.mp3';

const initialState = {
  shoppingIntense: '',
  paperRecycling: '',
  plasticRecycling: '',
  bottlesRecycling: '',
  foodIntake: '',
  page: 1,
};
const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return { ...state, ...action.payload };
    case 'NextPage':
      return { ...state, page: state.page + 1 };
    case 'PreviousPage':
      return { ...state, page: state.page - 1 };
    case 'updateState':
      return { ...state, [action.e.target.name]: action.e.target.value };
    default:
      throw new Error('Unexpected action');
  }
};

const WaterWaste = (props) => {
  const { t } = useTranslation();
  const componentWillUnmount = useRef(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const questionMap = waterWasteQuestionMap.filter((q) => q.page === state.page);

  const [playNavChange] = useSound(PageChangeSound);
  const [playButtonSound] = useSound(ButtonSound);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      const impact = calculateWaterWasteImpact(props.waterData.impactInfo, state);
      // eslint-disable-next-line no-console
      console.log(`water waste:  ${impact}`); // temp
      props.storeData({
        actionType: WATER,
        impactInfo: props.waterData.impactInfo,
        shopInfo: state,
        waterInfo: props.waterData.waterInfo,
        impact,
      });
    }
  }
  // eslint-disable-next-line
	, [state, props.waterData.shopInfo])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({ type: 'update', payload: props.waterData.shopInfo });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isNextDisabled = () => {
    for (let i = 0; i < questionMap.length; i++) {
      if (!state[questionMap[i].name]) {
        return true;
      }
    }
    return false;
  };

  const saveData = (e) => {
    const shopInfo = { ...state, actionType: WATER_WASTE };
    shopInfo[e.target.name] = e.target.value;
    props.storeData(shopInfo);
  };

  const disableHeaderNext = state.page === 3 ? isNextDisabled() : true;

  return (
    <>
      <Header disableNext={disableHeaderNext} />

      <main className="main">
        <div className="default-wrapper">
          {questionMap.map((q) => (
            <div className="form-row" key={q.name}>
              <h2>{t(q.t_name)}</h2>
              <ul className="buttons-list">
                {q.options.map((opt) => (
                  <li key={opt.value}>
                    <button
                      name={q.name}
                      value={opt.value}
                      className={`btn-secondary ${(state[q.name] === opt.value) && 'active'}`}
                      onClick={(e) => {
                        saveData(e);
                        playButtonSound();
                        dispatch({ type: 'updateState', e });
                      }}
                    >
                      {t(opt.t_name)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          ))}

        </div>
      </main>

      <BottomNavButtons
        pageNum="6"
        isNextDisabled={isNextDisabled()}
        onNextClick={() => { playNavChange(); dispatch({ type: 'NextPage' }); }}
        onBackClick={() => { playNavChange(); dispatch({ type: 'PreviousPage' }); }}
        next={`/ef-calculator/${state.page === 3 ? 'footprint' : 'water-waste'}`}
        previous={`/ef-calculator/${state.page === 1 ? 'water-break' : 'water-waste'}`}
      />
    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ waterData: efCalculatorReducer.water });
}

export default connect(mapStateToProp, { storeData })(withRouter(WaterWaste));
