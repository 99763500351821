/* eslint-disable react/no-danger */
import { toPng } from 'html-to-image';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import Header from 'ef-calculator/components/header';
import PdfReport from 'ef-calculator/components/PdfReport';

import FinalSound from 'assets/sounds/final.wav';
import 'ef-calculator/pages/take-action/index.scss';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ImgPaper from 'ef-calculator/assets/images/paper-colored.svg';
import ImgWater from 'ef-calculator/assets/images/water-colored.svg';
import ImgPlastic from 'ef-calculator/assets/images/plastic-colored.svg';
import ImgElectricity from 'ef-calculator/assets/images/electricity-colored.svg';
import ImgTransportation from 'ef-calculator/assets/images/transportation-colored.svg';

const TakeAction = (props) => {
  const [textImg, setTextImg] = useState(null);
  const transportImpact = Math.round((props.transportationImpact + Number.EPSILON) * 100) / 100;
  const electricityImpact = Math.round((props.electricityImpact + Number.EPSILON) * 100) / 100;
  const chartImgMap = {
    carbonChartImg: props.carbonChart,
    waterChartImg: props.waterChart,
  };
  const plasticImpact = Math.round((props.plasticImpact + Number.EPSILON) * 100) / 100;
  const paperImpact = Math.round((props.paperImpact + Number.EPSILON) * 100) / 100;
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';
  const { t } = useTranslation();
  const actionText = React.createRef();
  const finalSound = new Audio(FinalSound);
  const pageChangeSound = new Audio(PageChangeSound);

  const categoryMap = [
    {
      key: `transportTA${transportImpact === 0 ? 'Zero' : ''}`,
      impact: transportImpact,
      alt: 'Transport',
      image: ImgTransportation,
    },
    {
      key: `paperTA${paperImpact === 0 ? 'Zero' : ''}`,
      impact: paperImpact,
      alt: 'Paper',
      image: ImgPaper,
    },
    {
      key: `electricityTA${electricityImpact === 0 ? 'Zero' : ''}`,
      impact: electricityImpact,
      alt: 'Electricity',
      image: ImgElectricity,
    },
    {
      key: `plasticTA${plasticImpact === 0 ? 'Zero' : ''}`,
      impact: plasticImpact,
      alt: 'Plastic',
      image: ImgPlastic,
    },
    {
      key: `waterTA${props.waterImpact < 146615 ? 'BelowAvg' : ''}`,
      impact: props.waterImpact,
      alt: 'Water',
      image: ImgWater,
    },
  ];

  const captureActionText = () => {
    toPng(document.getElementById('textSection'))
      .then((dataUrl) => {
        setTextImg(dataUrl);
      });
  };

  useEffect(() => {
    finalSound.play();
    captureActionText();
    return function cleanup() {
      finalSound.pause();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header handleNavChange={() => { }} />

      <main className="main">
        <div className="default-wrapper action-block">
          <h2>{t('takeAction')}</h2>
          <div id="textSection" ref={actionText}>
            {
              categoryMap.map(((category) => (
                <div key={category.key} className={`icon-box ${isUrduLang && 'rtl'}`}>
                  <div className="icon-wrap ">
                    <img src={category.image} alt={category.alt} />
                  </div>
                  <div
                    className="action-text"
                    dir={isUrduLang ? 'rtl' : ''}
                    dangerouslySetInnerHTML={{ __html: t(category.key, { impact: category.impact }) }}
                  />
                </div>
              )))
            }
          </div>
        </div>
      </main>

      <div className="footer-buttons">
        <Link to="/ef-calculator/footprint" className="btn btn-primary" onClick={() => pageChangeSound.play()}>
          {t('back')}
        </Link>
        <PDFDownloadLink
          className="btn btn-primary"
          fileName="efc-report.pdf"
          document={(
            <PdfReport userName={props.userName} textImage={textImg} chartImages={chartImgMap} />
          )}
        >
          {/* eslint-disable-next-line no-unused-vars */}
          {({ blob, url, loading, error }) => (loading ? 'Generating PDF' : 'Download Report')}
        </PDFDownloadLink>

        <p className="close-text"> {isUrduLang ? <span dir="rtl">{t('closeText')}</span> : t('closeText')} </p>
        <strong className="step-count">8/8</strong>
      </div>

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({
    userName: efCalculatorReducer.start.userName,
    transportationImpact: efCalculatorReducer.transportation.impact,
    paperImpact: efCalculatorReducer.paper.impact,
    electricityImpact: efCalculatorReducer.electricity.impact,
    plasticImpact: efCalculatorReducer.plastic.impact,
    waterImpact: efCalculatorReducer.water.impact,
    carbonChart: efCalculatorReducer.footprint.chart,
    waterChart: efCalculatorReducer.waterGraph.chart,
  });
}

export default connect(mapStateToProp)(withRouter(TakeAction));
