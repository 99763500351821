import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import MessengerImg from 'go-phishing/assets/images/messenger.svg';

const Messenger = () => {
  const [answer, setAnswer] = useState('');
  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';
  const answerFeedback = {
    correct: 'Remember: Unless it’s a shared account, you should never share the login ID and '
            + 'password for your work account with anyone at the organization. Your login details are '
            + 'supposed to be confidential. In this case, make sure to gently inform your team member '
            + 'that this behavior does not conform to information security principles!',
    wrong: 'Remember: Unless it’s a shared account, you should never share the login ID and '
            + 'password for your work account with anyone at the organization. Your login details are '
            + 'supposed to be confidential. In this case, make sure to gently inform your team member '
            + 'that this behavior does not conform to information security principles!',
  };
  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                <img alt="Phishing Screen" src={MessengerImg} />
              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={2}
            next="bank-sms"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Looks like he really needs your help."
            helpText="A friend in need is a friend indeed!"
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(Messenger);
