import { createReducer } from '@reduxjs/toolkit';
import { WORK } from './actions';

const initialState = {
  work: {},
};

const ergonomicsReducer = createReducer(initialState, {
  [WORK]: (state, action) => ({ ...state, work: action.payload }),
});

export default ergonomicsReducer;
