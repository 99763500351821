import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import RecipientBox from 'go-phishing/components/RecipientBox';
import InfoSection from 'go-phishing/components/InfoSection';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import WarningSign from 'go-phishing/assets/images/warning-sign.svg';
import ExcelDownloadImg from 'go-phishing/assets/images/excel-download.svg';

const ExcelDownload = (props) => {
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);
  const [answer, setAnswer] = useState('');

  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const { data } = props.location;
  const answerFeedback = {
    correct: 'The file has been sent by someone who’s not in your organization. Excel files can '
            + 'potentially be hiding malware in the form of macro viruses, so you want to be extra careful '
            + 'before opening any email attachments you receive!',
    wrong: 'The file has been sent by someone who’s not in your organization. Excel files can '
            + 'potentially be hiding malware in the form of macro viruses, so you want to be extra careful '
            + 'before opening any email attachments you receive!',
  };
  const senderInfo = {
    name: 'Wasif Khan',
    email: `WasifKhan1991@not-${data && data.orgName}.com`,
    subject: 'Financial info',
    userEmail: data && data.userEmail,
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                <div className="attachment-bg" />
                {
                    answer === 'legitimate'
                    && (
                    <div className="warning-sign name">
                      <img alt="Warning" src={WarningSign} />
                    </div>
                    )
                }
                <div
                  className="target-link sender"
                  onMouseEnter={() => setShowSenderInfoBox(true)}
                  onMouseLeave={() => setShowSenderInfoBox(false)}
                >
                  {
                    showSenderInfoBox
                    && <RecipientBox senderInfo={senderInfo} />
                  }
                </div>
                {
                    answer === 'legitimate'
                    && (
                    <div className="warning-sign excel-file">
                      <img alt="Warning" src={WarningSign} />
                    </div>
                    )
                }
                <img alt="Phishing Screen" src={ExcelDownloadImg} />
              </div>

            </div>
          </div>
          <InfoSection
            answer={answer}
            currentPage={7}
            next="onedrive"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Proficient in Excel"
            helpText="At least that’s what it said on your CV."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(ExcelDownload);
