// eslint-disable-next-line import/prefer-default-export
export const TERMS = {
  ltr: 'The content and material contained herein (“Content”) was created and/or authored by'
    + ' ilmX, unless otherwise indicated. ilmX is the owner / licensee of all intellectual '
    + 'property rights, including copyright in the Content. The Content is protected by copyright laws and treaties '
    + 'around the world and all such rights are expressly reserved. Copying and / or transmitting portions or all of '
    + 'the Content without ilmX’s express written permission may be a violation of the applicable laws.\n\n'
    + 'The Content may also contain information, text and images created and / or prepared by individuals or '
    + 'institutions other than ilmX that may be protected by copyright. Information and text have either '
    + 'been purchased, used with permission, credited and linked back to the original sources or believed to be in '
    + 'the public domain. It is believed that this constitutes a ‘fair use’ of any such copyrighted material under '
    + 'the applicable laws. Users must seek permission from the copyright owner(s) to use any copyrighted Content for '
    + 'purposes that go beyond ‘fair use.’\n\n'
    + 'While we have made every attempt to ensure that the information contained in this site has been obtained from '
    + 'reliable sources, ilmX is not responsible for any errors or omissions, or for the results obtained '
    + 'from the use of this information. ilmX makes no representations and does not guarantee the '
    + 'accuracy, reliability or completeness of the Content and accepts no responsibility or liability for any '
    + 'omissions or errors (including, without limitation, typographical errors and technical errors) in the Content '
    + 'whatsoever or for reliance thereon. All information in this site is provided "as is", with no guarantee of '
    + 'completeness, accuracy, timeliness or of the results obtained from the use of this information, and without '
    + 'warranty of any kind, express or implied, including, but not limited to warranties of performance and fitness '
    + 'for a particular purpose. In no event will ilmX, its related partnerships or corporations, or the '
    + 'partners, agents or employees thereof be liable to you or anyone else for any decision made or action taken in '
    + 'reliance on the information in this site or for any consequential, special or similar damages, even if advised '
    + 'of the possibility of such damages.\n\n'
    + 'The information contained herein is for general guidance on matters of interest only. The application and '
    + 'impact of laws can vary widely based on the specific facts involved. Given the changing nature of laws, rules '
    + 'and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or '
    + 'inaccuracies in information contained in this site. Accordingly, the information herein is provided with the '
    + 'understanding that the authors and publishers are not engaged in rendering legal, medical, or other '
    + 'professional advice and services. As such, it should not be used as a substitute for consultation with '
    + 'specialized professional services or other competent advisers. Before making any decision or taking any action, '
    + 'you should consult a ilmX professional.\n\n'
    + 'Among the types of Personal Data that this site may collect, include: Cookies; Usage Data; email address; first '
    + 'name; address; username; company name; last name; phone number; country; Data communicated while using the '
    + 'service. ilmX reserves the right to remove or make any amendments to any of the Content without '
    + 'any notice. We also reserve the right to amend these terms and conditions and it’s linking policy at any time.',
  rtl: 'زیرِ نظر مواد اوراس کے تمام مندرجات کے مصنف/ خالق ilmX ہیں بجز یہ کہ'
    + ' اس کے برعکس واضح کیا جائے۔ اس مواد اور اس کے تمام'
    + ' تر مندرجات سمیت اس کے لائسینس اورکاپی رائیٹ کے جملہ حقوق، بحق ilmX محفوظ ہیں۔ یہ مواد دنیا'
    + ' بھرمیں رائج کاپی رائیٹ قوانین اورمعاہدوں کے تحت محفوظ شدہ مواد ہے لہٰذا اس مواد یا اس کے مندرجات کی کلی یا'
    + ' جزوی طور پرنقل یا بلا اجازتِ ilmX، نشر و اشاعت متعلقہ قوانین کی خلاف ورزی تصور کی جائے گی۔\n\n'
    + 'یہ مواد ایسی معلومات، ٹیکسٹ اور تصاویر پر مبنی ہو سکتا ہے جسے ilmX '
    + 'کے علاوہ کسی دوسرے افراد یا اداروں نے'
    + ' تخلیق/ تیار کیا ہو اورجس پر کاپی رائیٹ کا اطلاق ہوتا ہو۔ تاہم ایسی تمام معلومات اور ٹیکسٹ یا تو متعلقہ '
    + 'فریق سے خرید لیے گئے ہیں یا ان کی اجازت سے استعمال میں لائے گئے ہیں یا ایسے تمام مواد کو ان کے اصل اور حقیقی '
    + 'ذرائع سے منسوب کیا گیا ہے یا یہ یقین کیا جاتا ہے کہ یہ معلومات عوام کے آزادانہ استعمال کے لیے دستیاب ہیں۔ '
    + 'یہ بھی یقین کیا جاتا ہے کہ حق تصنیف سے جائز حد تک استفادہ کیا جائے گا اور صارفین کاپی رائیٹ کے تحت '
    + 'محفوظ شدہ اس مواد کا ان معاملات میں جو"جائز حد" سے تجاوز کرتے ہوں، بلا اجازتِ مالک استعمال نہیں کریں گے۔\n\n'
    + 'اگرچہ یہ کوشش کی گئی ہے کہ اس سائیٹ پر موجود تمام معلومات مستند'
    + ' اور قابلِ اعتبار ذرائع سے حاصل کی جائیں تاہم ilmX اس معلومات یا اس کے استعمال سے حاصل کردہ'
    + ' نتائج میں کسی قسم کے سہو یا بھول چُوک کے ذمہ دار نہیں ہیں۔ ilmX اس مواد اور اس کے مندرجات'
    + ' کی نمائندگی نہیں کرتے اور نہ ہی ان پر اس مواد کے درست، قابلِ انحصار یا مکمل ہونے کی ذمہ داری عائد ہوتی'
    + ' ہے۔ مزید برآں، ilmX اس مواد یا اس کے مندرجات میں کسی قسم کے تحریری یا تیکنیکی سہو کی'
    + ' ذمہ داری یا اس حوالے سے جوابدہی سے بھی آزاد ہیں۔ اس سائیٹ پر موجود تمام معلومات "جو ہے، جیسا ہے" کی بنا'
    + ' پر فراہم کی گئی ہیں اور اس معلومات یا اس سے حاصل کردہ نتائج کے مکمل، درست یا بروقت ہونے سمیت دیگر کسی بھی'
    + ' مخصوص مقصد یا کام کے لیے اس مواد کے موزوں ہونے کے حوالے سے ilmX کی جانب سے کسی بھی قسم کی'
    + ' زبانی یا تحریری یقین دہانی فراہم نہیں کی گئی۔ لہٰذا واضح کیا جاتا ہے کہ اس معلومات/ مواد اور اس کے مندرجات'
    + ' کی بنا پر کیے جانے والے کسی فیصلے کے نتیجے میں ہونے والے کسی حادثاتی نقصان کی صورت میں ilmX'
    + ' یا ان کا کوئی شراکتی ادارہ، ایجنٹ یا کارکن یا دیگر کوئی بھی متعلقہ فرد ذمہ دار نہیں ہوگا۔\n\n'
    + 'فراہم کردہ اس معلومات کا مقصد متعلقہ دلچسپی کے امور پرعمومی رہنمائی کی فراہمی'
    + ' ہے تاہم اس میں شامل مخصوص شواہد کی بنا پر متعلقہ قوانین کے اطلاق اوران کی افادیت میں ردوبدل بھی'
    + ' ممکن ہے۔ قوانین و ضوابط کی متغیر صورتحال اور برقی خط کتابت کولاحق ناگزیر خطرات کے پیشِ نظر، اس سائیٹ پر'
    + ' موجود معلومات کی فراہمی میں تاخیر ہونے، معلومات کے نامکمل یا درست نہ ہونے کے امکانات بھی موجود ہیں۔'
    + ' اسی طرح، یہ معلومات اس اتفاق رائے کے ساتھ پیش کی جارہی ہے کہ اس کے مصنفین اور ناشران اس مواد کا استعمال کسی'
    + ' قسم کی قانونی، طبی یا دیگر پیشہ ورانہ مشاورت یا خدمات کی فراہمی کے لیے نہیں کریں گے۔ واضح کیا جاتا ہے'
    + ' کہ فراہم کردہ اس معلومات کو متعلقہ امور کے ماہرین اور دیگر پیشہ ورانہ مہارت رکھنے والے افراد سے مشاورت'
    + ' کا متبادل نہ سمجھا جائے۔ اس ضمن میں کسی قسم کا فیصلہ یا اقدام کرنے سے پہلے ilmX'
    + ' سے وابستہ کسی متعلقہ ماہرپیشہ ور کارکن سے رابطہ اور مشاورت کی جائے۔\n\n'
    + 'یہ سائیٹ درج ذیل نوعیت کا "ذاتی ڈیٹا" اکٹھا کر سکتی ہے: کوکیز، ڈیٹا کا استعمال،'
    + ' ای میل ایڈریس، صارف کا نام، پتہ، یوزر نام، کمپنی نام، آخری نام، فون نمبراور ملک وغیرہ، نیزاس سروس کے'
    + ' استعمال کے دوران ہونے والے ڈیٹا کے تبادلہ کے حوالے سے معلومات بھی یہ سائیٹ اکٹھی کر سکتی ہے۔ ilmX'
    + ' بغیر پیشگی خبر کیے، فراہم کردہ مواد کو حذف کرنے یا اس میں کسی قسم کی ترمیم کرنے کا مجاز ہے۔'
    + ' ہم ان شرائط و ضوابط اور ان سے منسلک پالیسی میں کسی بھی وقت ترمیم کرنے کا حق بھی محفوظ رکھتے ہیں۔',
};
