import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import useSound from 'use-sound';

import InfoSection from 'go-phishing/components/InfoSection';
import RecipientBox from 'go-phishing/components/RecipientBox';

import ButtonSound from 'assets/sounds/button.mp3';
import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import CredentialsImg from 'go-phishing/assets/images/credentials-tab.svg';
import PasswordTheftImg from 'go-phishing/assets/images/password-theft.svg';

const PasswordTheft = (props) => {
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);
  const [showCredImg, setShowCredImg] = useState(false);
  const [answer, setAnswer] = useState('');
  const [play] = useSound(ButtonSound);

  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const { data } = props.location;
  const answerFeedback = {
    correct: 'Although this email is actually from your work colleague, note that any application '
            + 'should not be asking for your personal passwords and login details. Instead, if there are any '
            + 'passwords on a document, your work colleague should probably provide it to you.',
    wrong: 'Although this email is actually from your work colleague, note that any application '
            + 'should not be asking for your personal passwords and login details. Instead, if there are any '
            + 'passwords on a document, your work colleague should probably provide it to you.',
  };
  const senderInfo = {
    name: 'Sana Saleem',
    email: `Sana19Saleem@${data && data.orgName}.com`,
    subject: 'Google Doc Link',
    userEmail: data && data.userEmail,
  };

  const handleResponse = () => {
    play();
    setShowCredImg(!showCredImg);
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                {
                    showCredImg
                      ? <img alt="Phishing Screen" src={CredentialsImg} />
                      : <img alt="Phishing Screen" src={PasswordTheftImg} />
                }
                <div
                  className={`target-link ${showCredImg ? 'cred-cancel' : 'open-doc'}`}
                  onClick={() => handleResponse()}
                />

                {
                    !showCredImg
                    && (
                    <div
                      className="target-link sender"
                      onMouseEnter={() => setShowSenderInfoBox(true)}
                      onMouseLeave={() => setShowSenderInfoBox(false)}
                    >
                      {
                        showSenderInfoBox
                        && <RecipientBox senderInfo={senderInfo} />
                      }
                    </div>
                    )
                }
              </div>

            </div>
          </div>
          <InfoSection
            answer={answer}
            currentPage={9}
            next="final"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="The budget’s in!"
            helpText="Let’s hope they upped yours."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(PasswordTheft);
