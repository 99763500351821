import React from 'react';
import Logo from 'assets/images/logo.png';

import 'common/components/footer/index.scss';

function Footer() {
  return (
    <footer className="footer">
      <div className="logo-row">
        <span className="text-powered">Powered By</span>
        <strong className="footer-logo"><img src={Logo} alt="ilmX" /></strong>
      </div>
    </footer>
  );
}

export default Footer;
