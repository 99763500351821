import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseComponent from 'ergonomics/components/BaseComponent';

import User from 'ergonomics/assets/images/user-20-rule.svg';
import WorkTable from 'ergonomics/assets/images/table-adjusted-work.svg';
import UserCorrectImg from 'ergonomics/assets/images/user-correct-position.svg';

const Rule20 = () => {
  const correctAssetsMap = {
    userCorrectImg: UserCorrectImg,
  };

  return (
    <>
      <div className="info-box-wrap">
        <BaseComponent
          current="Rule20"
          beforeClicked="rule-20"
          afterClicked="rule-20-clicked"
          userClassName="user-feet-screen"
          pcClassName="pc-position-up"
          UserImg={User}
          PCImg={WorkTable}
          CorrectAssetsMap={correctAssetsMap}
          currentPage={5}
          nextLink="final"
        />
      </div>
    </>
  );
};

export default withRouter(Rule20);
