import React from 'react';
import i18next from 'i18next';
import useSound from 'use-sound';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'ergonomics/assets/scss/components/_info-box.scss';

const Info = () => {
  const [play] = useSound(PageChangeSound);
  const { t } = useTranslation();
  const langDir = i18next.dir();

  return (
    <>
      <div className="info-box-wrap">
        <div className="btn-center" style={{ padding: '30px' }}>
          <h1>{t('instructions')}</h1>
          <h3 dir={langDir}>{t('ergInstructions')}</h3>
          <div>
            <Link className="btn btn-primary" to="/ergonomics/back-pain" onClick={() => play()}>
              {t('next')}
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Info);
