export const transportationConversionFactorMap = {
  wfh: 0,
  feet: 0,
  bicycle: 0,
  bus: 9697.439876,
  car: 6786.885023,
  rickshaw: 22634.16401,
  hybridcar: 9053.706587,
  motorcycle: 22634.16401,
};

export const applianceKwhMap = {
  heater: 12,
  laptop: 0.32,
  tubeLights: 0.4,
  computerAcc: 1.6,
  cellingFan: 0.64,
  laserPrinter: 3.6,
  esTubeLights: 0.192,
  waterDispenser: 0.8,
  inkJetPrinter: 0.64,
  ac: { 1: 12, 1.5: 16, 2: 20 },
  inverterAC: { 1: 7.2, 1.5: 9.6, 2: 12 },
};

export const plasticConversionFactorMap = {
  petBottles: 0.07605141075,
  plasticBags: 0.3422313484,
  taPlasticBox: 0.0855578371,
  taPlasticCup: 0.1368925394,
  straws: 5.475701574,
  disposableCutlery: 0.6844626968,
  foodWrappers: 0.1825233858,
};

export const waterWasteQuestionMap = [
  {
    t_name: 'shopIntenseQT',
    name: 'shoppingIntense',
    page: 1,
    options:
            [{ t_name: 'shopBasic', value: 'BASIC' },
              { t_name: 'shopModerate', value: 'MODERATE' },
              { t_name: 'shopEverything', value: 'EVERYTHING' }],
  },
  {
    t_name: 'shopPaperRecyclingQT',
    name: 'paperRecycling',
    page: 1,
    options:
            [{ t_name: 'never', value: 'NEVER' },
              { t_name: 'sometimes', value: 'SOMETIMES' },
              { t_name: 'always', value: 'ALWAYS' }],
  },
  {
    t_name: 'shopPlasticRecyclingQT',
    name: 'plasticRecycling',
    page: 2,
    options:
            [{ t_name: 'never', value: 'NEVER' },
              { t_name: 'sometimes', value: 'SOMETIMES' },
              { t_name: 'always', value: 'ALWAYS' }],
  },
  {
    t_name: 'shopBottlesRecyclingQT',
    name: 'bottlesRecycling',
    page: 2,
    options: [{ t_name: 'never', value: 'NEVER' },
      { t_name: 'sometimes', value: 'SOMETIMES' },
      { t_name: 'always', value: 'ALWAYS' }],
  },
  {
    t_name: 'shopFoodIntakeQT',
    name: 'foodIntake',
    page: 3,
    options: [{ t_name: 'shopVegan', value: 'VEGAN' },
      { t_name: 'shopVegetarian', value: 'VEG' },
      { t_name: 'shopMeat', value: 'MEAT' }],
  },
];
