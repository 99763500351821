import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';
import RecipientBox from 'go-phishing/components/RecipientBox';
import EmbeddedLink from 'go-phishing/components/EmbeddedLink';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import OneDriveImg from 'go-phishing/assets/images/onedrive.svg';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';

const OneDrive = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);
  const [answer, setAnswer] = useState('');

  const correctAnswer = 'legitimate';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const { data } = props.location;
  const answerFeedback = {
    correct: 'This is a legitimate email! If you hover over the Onedrive '
            + 'button, it leads you to the actual Onedrive website for you to login.',
    wrong: 'This is a legitimate email! If you hover over the Onedrive '
            + 'button, it leads you to the actual Onedrive website for you to login.',
  };
  const senderInfo = {
    name: 'OneDrive',
    email: 'automated@onedrive.com',
    subject: 'OneDrive Discount!',
    userEmail: data && data.userEmail,
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                <img alt="Phishing Screen" src={OneDriveImg} />
                <div
                  className="target-link sender"
                  onMouseEnter={() => setShowSenderInfoBox(true)}
                  onMouseLeave={() => setShowSenderInfoBox(false)}
                >
                  {
                    showSenderInfoBox
                    && <RecipientBox senderInfo={senderInfo} />
                  }
                </div>

                <div
                  className="target-link one-drive"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  { isHovering && <EmbeddedLink link="https://onedrive.live.com" /> }
                </div>

              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={8}
            next="password-theft"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Great! 50% off!"
            helpText="Now you can continue to ignore this app at half the price!"
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(OneDrive);
