import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';
import EmbeddedLink from 'go-phishing/components/EmbeddedLink';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import WarningSign from 'go-phishing/assets/images/warning-sign.svg';
import ConsentPhishingImg from 'go-phishing/assets/images/consent-phishing.svg';

const ConsentPhishing = () => {
  const [isHovering, setIsHovering] = useState(false);
  const [answer, setAnswer] = useState('');
  const answerFeedback = {
    correct: 'This strange app is pretending to be Goodreads to gain access to your inbox, '
            + 'photos, and Google Drive! Always be cautious when providing permissions to apps that'
            + ' may have malicious intent.',
    wrong: 'This strange app is pretending to be Goodreads to gain access to your inbox, '
            + 'photos, and Google Drive! Always be cautious when providing permissions to apps that'
            + ' may have malicious intent.',
  };
  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                {
                    answer === 'legitimate'
                    && (
                      <div className="warning-sign consent">
                        <img alt="Warning" src={WarningSign} />
                      </div>
                    )
                }
                <div
                  className="target-link good-reads"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  { isHovering && <EmbeddedLink link="www.g0od-readz.com" /> }

                </div>

                <img alt="Phishing Screen" src={ConsentPhishingImg} />

              </div>

            </div>
          </div>
          <InfoSection
            answer={answer}
            currentPage={4}
            next="bad-reply"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="You only read two books last year."
            helpText="Maybe this’ll help you turn that to three."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(ConsentPhishing);
