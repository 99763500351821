import React from 'react';

const PaginationDots = (props) => {
  function renderPaginationDots(currentPage, totalPage) {
    const dots = [];
    for (let i = 1; i <= totalPage; i += 1) {
      if (i === currentPage) {
        dots.push(<span className="active" key={i}> </span>);
      } else if (i < currentPage) {
        dots.push(<span className="previous" key={i}> </span>);
      } else {
        dots.push(<span key={i}> </span>);
      }
    }
    return dots;
  }

  return (
    <div className="pagination-wrapper">
      <div className="pagination">
        { renderPaginationDots(props.currentPage, props.total) }
      </div>
      <div className="counter-state">{`${props.currentPage}/${props.total}`}</div>
    </div>
  );
};

export default PaginationDots;
