import React from 'react';
import useSound from 'use-sound';
import { Link, withRouter } from 'react-router-dom';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const AnswerSection = (props) => {
  const { answer, nextPath, feedbackText } = props;
  const [play] = useSound(PageChangeSound);

  return (
    <div>
      <div className={`check-list-wrap ${answer}`}>
        <h3>
          <span className="title-case">{answer}!</span>
          <small>{ feedbackText }</small>
        </h3>
      </div>
      {
          answer === 'correct'
          && (
            <div className="btn-center">
              <Link
                to={{ pathname: `/phishing/${nextPath}`, data: props.location.data }}
                onClick={() => play()}
                className="btn btn-primary"
              >
                Next
              </Link>
            </div>
          )
        }
    </div>
  );
};

export default withRouter(AnswerSection);
