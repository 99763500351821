import React from 'react';

const NumericInput = (props) => {
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';

  function handleInputChange(event) {
    props.handleInputChange(event);
  }

  return (
    <div className="form-box">
      <input
        className={`form-control ${props.value && 'input-focused'}`}
        pattern="[0-9]*"
        value={props.value}
        maxLength={props.maxLength}
        onInput={handleInputChange.bind(this)}
      />
      <span className="input-label">{props.text} </span>
      {
        props.errorText
        && (
          <p className={`shared-hint ${props.isDanger && 'danger'} ${isUrduLang && 'urdu-lang'}`}>
            {props.errorText}
          </p>
        )
      }
    </div>
  );
};

export default NumericInput;
