import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import useSound from 'use-sound';
import i18next from 'i18next';

import Footer from 'common/components/footer';
import { TERMS } from 'ef-calculator/terms';

import 'common/pages/get-started/index.scss';
import NextSound from 'assets/sounds/swoosh.mp3';
import StartScreenSound from 'assets/sounds/welcome.wav';

const GetStarted = (props) => {
  const { t } = useTranslation();
  const langDir = i18next.dir();
  const termsText = TERMS[langDir];
  const paramsMap = new URLSearchParams(props.location.search);
  const userData = {
    userName: paramsMap.get('name'),
    orgName: paramsMap.get('org_name'),
    userEmail: paramsMap.get('user_email'),
  };

  const [play, { stop }] = useSound(StartScreenSound);
  const [playNext] = useSound(NextSound);
  useEffect(() => {
    play();
    return function cleanup() {
      stop();
    };
  }, [play, stop]);

  return (
    <div className="wrapper landing">
      <div className="w1">
        <span className="logo">
          <img src={props.logo} alt="ilmX" />
        </span>
        <h1 className={langDir}> { props.title } </h1>
        <div className="text-area">
          <div className="text-frame">
            <div className="scroll">
              <p dir={langDir} className={`terms-conditions ${langDir}`}>
                { termsText }
              </p>
            </div>
          </div>
          {langDir === 'rtl'
            ? (
              <div className="urdu-lang-terms">
                {t('termsCheckbox')}
              </div>
            )
            : (
              <div className="text-label">
                {t('termsCheckbox')} <strong>{t('termsCheckboxCondition')}</strong>
              </div>
            )}
        </div>
        <Link
          to={{ pathname: props.next, data: userData }}
          className="btn btn-primary"
          onClick={() => playNext()}
        >
          {t('termsGetStartedBtn')}
        </Link>
      </div>
      <Footer />
    </div>
  );
};

export default withRouter(GetStarted);
