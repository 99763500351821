import {
  transportationConversionFactorMap,
  applianceKwhMap,
  plasticConversionFactorMap,
  waterWasteQuestionMap,
} from './constants';

export const calculateTransportationCarbonEmission = (vehicle, distance) => {
  if (!(distance && vehicle) || transportationConversionFactorMap[vehicle] === 0) {
    return 0;
  }
  return (parseInt(distance) * 520) / transportationConversionFactorMap[vehicle];
};

export const calculatePaperCarbonEmission = (pCount, duplex) => {
  const pagesCount = parseInt(pCount);
  if (pagesCount === 0 || !(pagesCount && duplex)) {
    return 0;
  }

  const paperConversionFactorMap = { yes: 26, no: 52, sometimes: 39 };
  return ((pagesCount * paperConversionFactorMap[duplex]) / 0.022) * 0.000453592;
};

export const calculateElectricCarbonEmission = (state, electricInfo) => {
  const {
    tubeLights, esTubeLights, waterDispenser, cellingFan, inkJetPrinter,
    laserPrinter, computerAcc, laptop, heater, ac, inverterAC, acT, inverterACT,
  } = state;

  const sumApplianceKwh = (tubeLights * applianceKwhMap.tubeLights) + (esTubeLights * applianceKwhMap.esTubeLights)
		+ (inkJetPrinter * applianceKwhMap.inkJetPrinter) + (laserPrinter * applianceKwhMap.laserPrinter)
		+ (waterDispenser * applianceKwhMap.waterDispenser) + (cellingFan * applianceKwhMap.cellingFan)
		+ (computerAcc * applianceKwhMap.computerAcc) + (laptop * applianceKwhMap.laptop)
		+ (heater * applianceKwhMap.heater) + (ac * applianceKwhMap.ac[acT])
		+ (inverterAC * applianceKwhMap.inverterAC[inverterACT]);

  const spaceTypeFactor = electricInfo.space === 'shared' ? electricInfo.peopleCount : 1;
  return ((sumApplianceKwh * 260) / 2159.827214) / spaceTypeFactor;
};

export const calculatePlasticCarbonEmission = (state) => {
  const { petBottles, plasticBags, taPlasticBox, taPlasticCup, straws, disposableCutlery, foodWrappers } = state;
  const co2Emission = (petBottles / plasticConversionFactorMap.petBottles)
		+ (disposableCutlery / plasticConversionFactorMap.disposableCutlery)
		+ (foodWrappers / plasticConversionFactorMap.foodWrappers)
		+ (taPlasticBox / plasticConversionFactorMap.taPlasticBox)
		+ (taPlasticCup / plasticConversionFactorMap.taPlasticCup)
		+ (plasticBags / plasticConversionFactorMap.plasticBags)
		+ (straws / plasticConversionFactorMap.straws);
  return co2Emission * 0.001;
};

export const calculateWaterConsumption = (state, allQuestionMap) => {
  const lowFaucetMap = { u5: -4, b5_10: -7, o15: -18 };
  const gallonMap = {
    faucetTime: { u5: 11, b5_10: 22, o15: 55 },
    lowFaucet: { yes: lowFaucetMap[state.faucetTime], no: 0, notSure: 0 },
    LFToilets: { yes: 8, no: 25, notSure: 25 },
  };
  let impact = 0;
  for (let i = 0; i < allQuestionMap.length; i++) {
    const question = allQuestionMap[i];
    impact += gallonMap[question.name][state[question.name]];
  }

  return impact;
};

export function calculateWaterWasteImpact(infoImpact, shopInfoAnswers) {
  const gallonMap = {
    shoppingIntense: { BASIC: 218, MODERATE: 437, EVERYTHING: 875 },
    paperRecycling: { NEVER: 0, SOMETIMES: -2, ALWAYS: -4 },
    plasticRecycling: { NEVER: 0, SOMETIMES: -9, ALWAYS: -17 },
    bottlesRecycling: { NEVER: 0, SOMETIMES: -18, ALWAYS: -35 },
    foodIntake: { VEGAN: 305, VEG: 422, MEAT: 962 },
  };
  let impact = 0;
  for (let i = 0; i < waterWasteQuestionMap.length; i++) {
    const question = waterWasteQuestionMap[i];
    impact += gallonMap[question.name][shopInfoAnswers[question.name]];
  }
  return (impact + infoImpact) * 260;
}
