import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useSound from 'use-sound';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const ChartBottomNav = (props) => {
  const { t } = useTranslation();
  const [playNavChange] = useSound(PageChangeSound);

  const nextClickHandler = () => {
    playNavChange();
    props.nextHandler();
  };

  return (
    <div className="footer-buttons">
      <Link to="/ef-calculator/water-waste" className="btn btn-primary" onClick={() => playNavChange()}>
        {t('back')}
      </Link>
      <button className="btn btn-primary" onClick={() => nextClickHandler()}>
        {t('next')}
      </button>
      <strong className="step-count">7/8</strong>
    </div>
  );
};

export default ChartBottomNav;
