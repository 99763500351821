import React from 'react';
import useSound from 'use-sound';
import { Link, withRouter } from 'react-router-dom';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const Instructions = (props) => {
  const [play] = useSound(PageChangeSound);
  return (
    <>
      <div className="info-box-wrap">
        <div className="btn-center" style={{ padding: '30px' }}>
          <h1>Instructions</h1>
          <p style={{ textAlign: 'left' }}>
            Online mobsters are present everywhere. It is your job to identify their attempts and
            stay safe from their antics! You’re going to need to determine
            which messages are <i>real</i>, and which are <i>fake</i>.<br /> <br />

            <strong>
              Remember: you can double-check all messages by interacting with them! Check the sender!
              Investigate web links! See if downloads are legit!
            </strong>
            <br /> <br />
            Best of luck. We’ll see you on the other side.
          </p>
          <div>
            <Link
              to={{ pathname: '/phishing/email', data: props.location.data }}
              className="btn btn-primary"
              onClick={() => play()}
            >
              Next
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(Instructions);
