import { useTranslation } from 'react-i18next';
import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, withRouter,
} from 'react-router-dom';

import Final from 'common/pages/final';
import NotFound from 'common/pages/404';
import Arms from 'ergonomics/pages/arms';
import Neck from 'ergonomics/pages/neck';
import Feet from 'ergonomics/pages/feet';
import Rule20 from 'ergonomics/pages/20Rule';
import Work from 'ergonomics/pages/work/index';
import Info from 'ergonomics/pages/info/index';
import BackPain from 'ergonomics/pages/backpain/index';
import GetStarted from 'common/pages/get-started/index';

import Logo from 'ergonomics/assets/images/logo-ergonomics.svg';

const Ergonomics = () => {
  const { t } = useTranslation();

  return (
    <Router>
      <Switch>
        <Route path="/ergonomics" exact>
          <GetStarted
            title={t('ergonomics')}
            next="/ergonomics/work"
            logo={Logo}
          />
        </Route>
        <Route>
          <div className="wrapper landing">
            <div className="w1">
              <Switch>
                <Route path="/ergonomics/work" component={Work} />
                <Route path="/ergonomics/info" component={Info} />
                <Route path="/ergonomics/back-pain" component={BackPain} />
                <Route path="/ergonomics/arms" component={Arms} />
                <Route path="/ergonomics/neck" component={Neck} />
                <Route path="/ergonomics/feet" component={Feet} />
                <Route path="/ergonomics/20-rule" component={Rule20} />
                <Route path="/ergonomics/final" component={Final} />
                <Route path="/ergonomics/*" component={NotFound} />
              </Switch>
            </div>
          </div>
        </Route>
      </Switch>
    </Router>
  );
};

export default withRouter(Ergonomics);
