import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import BottomNavButtons from 'common/components/BottomNavButtons';

import WaveSecond from 'ef-calculator/assets/images/wave-second.svg';
import WaveOne from 'ef-calculator/assets/images/wave-front.svg';
import WavesSound from 'assets/sounds/water-waves.mp3';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'ef-calculator/pages/water/index.scss';

const WaterBreak = (props) => {
  const [playNavChange] = useSound(PageChangeSound);
  const [playWavesSound, { stop }] = useSound(WavesSound, {
    loop: true,
  });

  const { t } = useTranslation();
  const waterConsumed = props.waterData.impactInfo ? props.waterData.impactInfo : 'X';

  useEffect(() => {
    playWavesSound();
    return function cleanup() {
      stop();
    };
    // eslint-disable-next-line
	}, [playWavesSound, stop])

  return (
    <>
      <Header disableNext={false} />

      <main className="main">
        <div className="default-wrapper">
          <div className="break-block">
            <h2>{t('waterBreakTitle')}</h2>
            <p>
              <strong>
                {t('waterBreak', { waterUsage: waterConsumed })}
              </strong>
            </p>
          </div>
        </div>
      </main>
      <div className="wave-first"><img src={WaveOne} alt="Water Wave" /></div>
      <div className="wave-second"><img src={WaveSecond} alt="Water Wave" /></div>

      <BottomNavButtons
        pageNum="6"
        isNextDisabled={false}
        previous="/ef-calculator/water"
        next="/ef-calculator/water-waste"
        onNextClick={() => { playNavChange(); }}
        onBackClick={() => { playNavChange(); }}
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ waterData: efCalculatorReducer.water });
}

export default connect(mapStateToProp)(withRouter(WaterBreak));
