import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { WORK } from 'mental-wellbeing/actions';
import BaseComponent from 'mental-wellbeing/components/BaseComponent';

const Work = ({ workData }) => {
  const initialState = {
    freqFail: '',
    freqFind: '',
    emotionalToll: '',
    lastMomentTask: '',
    conflictingJob: '',
    fairDistribution: '',
    aimsAndObjectives: '',
    necessaryInstructions: '',
    leadManagerialSkill: '',
    losingMyJob: '',
    leadershipQuality: '',
    entitledBreaks: '',
  };

  const allQuestionMap = [
    {
      text: 'I frequently fail to complete my work', type: 'negative', name: 'freqFail', currentPage: 1,
    },
    {
      text: 'I frequently find myself lacking the time to complete my work',
      type: 'negative',
      name: 'freqFind',
      currentPage: 1,
    },
    {
      text: 'My work takes an emotional toll on me', type: 'negative', name: 'emotionalToll', currentPage: 2,
    },
    {
      text: 'I am often given tasks at the last moment',
      type: 'negative',
      name: 'lastMomentTask',
      currentPage: 2,
    },
    {
      text: 'I often find myself with conflicting job tasks',
      type: 'negative',
      name: 'conflictingJob',
      currentPage: 3,
    },
    {
      text: 'I find that there is a fair distribution of work at my workplace',
      type: 'positive',
      name: 'fairDistribution',
      currentPage: 3,
    },
    {
      text: 'The aims and objectives given to me at work are clear',
      type: 'positive',
      name: 'aimsAndObjectives',
      currentPage: 4,
    },
    {
      text: 'I am given all the necessary instructions and materials to do my job well',
      type: 'positive',
      name: 'necessaryInstructions',
      currentPage: 4,
    },
    {
      text: 'I have confidence in my team lead\'s managerial skills',
      type: 'positive',
      name: 'leadManagerialSkill',
      currentPage: 5,
    },
    {
      text: 'I have confidence in the quality of leadership portrayed by my team lead or manager',
      type: 'positive',
      name: 'leadershipQuality',
      currentPage: 5,
    },
    {
      type: 'positive',
      name: 'entitledBreaks',
      currentPage: 6,
      text: 'I am encouraged to take my entitled '
				+ 'breaks (medical or non-medical leaves, including leaves for mental health, or illness)',
    },
    {
      text: 'I am worried about losing my job', type: 'negative', name: 'losingMyJob', currentPage: 6,
    },
  ];

  return (
    <BaseComponent
      maxPage={6}
      type={WORK}
      current="work"
      sectionPage={2}
      next="autonomy"
      previous="start"
      savedData={workData}
      initialState={initialState}
      allQuestionMap={allQuestionMap}
    />
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({ workData: mentalWellBeingReducer.work });
}

export default connect(mapStateToProp)(withRouter(Work));
