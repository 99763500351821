import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseComponent from 'ergonomics/components/BaseComponent';

import CorrectWorkTable from 'ergonomics/assets/images/table-adjusted-work.svg';
import UserCorrectImg from 'ergonomics/assets/images/user-feet.svg';
import WorkTable from 'ergonomics/assets/images/table-work.svg';
import User from 'ergonomics/assets/images/user-neck.svg';

const Neck = () => {
  const correctAssetsMap = {
    userCorrectImg: UserCorrectImg,
    correctTableImg: CorrectWorkTable,
    userClassName: 'user-feet-screen',
    pcClassName: 'pc-position-up',
  };

  return (
    <>
      <div className="info-box-wrap">
        <BaseComponent
          beforeClicked="neck"
          afterClicked="neck-clicked"
          userClassName="user-keyboard-on-table"
          pcClassName="pc-position"
          UserImg={User}
          CorrectAssetsMap={correctAssetsMap}
          PCImg={WorkTable}
          current="Neck"
          currentPage={3}
          nextLink="feet"
        />
      </div>
    </>
  );
};

export default withRouter(Neck);
