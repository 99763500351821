import React from 'react';

const Question = (props) => {
  function handleInputChange(event) {
    props.clickHandler(event);
  }

  return (
    <div className="form-row">
      <h2>{props.text}</h2>
      <ul className="buttons-list">
        <li className="four-opt">
          <button
            className={`btn-secondary ${(props.value === 'sta') && 'active'}`}
            value="sta"
            name={props.name}
            onClick={handleInputChange.bind(this)}
          >
            Strongly Agree
          </button>
        </li>
        <li className="four-opt">
          <button
            className={`btn-secondary ${(props.value === 'swa') && 'active'}`}
            value="swa"
            name={props.name}
            onClick={handleInputChange.bind(this)}
          >
            Somewhat Agree
          </button>
        </li>
        <li className="four-opt">
          <button
            className={`btn-secondary ${(props.value === 'swd') && 'active'}`}
            value="swd"
            name={props.name}
            onClick={handleInputChange.bind(this)}
          >
            Somewhat Disagree
          </button>
        </li>
        <li className="four-opt">
          <button
            className={`btn-secondary ${(props.value === 'std') && 'active'}`}
            value="std"
            name={props.name}
            onClick={handleInputChange.bind(this)}
          >
            Strongly Disagree
          </button>
        </li>
      </ul>
    </div>
  );
};

export default Question;
