import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import useSound from 'use-sound';
import i18next from 'i18next';

import WrongSound from 'assets/sounds/wrong.wav';
import QUESTION_OPT_MAP from 'ergonomics/questions';
import CorrectSound from 'assets/sounds/correct.wav';

const AnswerSection = ({ answer, clickHandler, current }) => {
  const [playCorrectSound, correctExtra] = useSound(CorrectSound);
  const [playWrongSound, wrongExtra] = useSound(WrongSound);
  const { t } = useTranslation();
  const langDir = i18next.dir();

  useEffect(() => {
    if (answer === 'correct') {
      playCorrectSound();
    } else if (answer === 'wrong') {
      playWrongSound();
    }

    return function cleanup() {
      correctExtra.stop();
      wrongExtra.stop();
    };
    // eslint-disable-next-line
    }, [answer]);

  return (
    <div className="check-list-wrap">
      <p className="select-heading">{ t(`erg${current}OptionTitle`) }</p>
      <ul className="select-item">
        {
          QUESTION_OPT_MAP[current].map((optMap) => (
            <li key={optMap.name}>
              <span
                dir={langDir}
                onClick={() => clickHandler(optMap.name)}
                className={answer === optMap.name ? optMap.name : ''}
              >
                {t(optMap.text)}
              </span>
            </li>
          ))
        }
      </ul>
    </div>
  );
};

export default AnswerSection;
