import { withRouter } from 'react-router-dom';
import useSound from 'use-sound';
import React from 'react';

import BottomNavButtons from 'common/components/BottomNavButtons';
import Header from 'mental-wellbeing/components/header';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const Starts = () => {
  const [playNavChange] = useSound(PageChangeSound);

  return (
    <>
      <Header disableNext={false} />

      <main className="main">
        <h1>Start</h1>
      </main>

      <BottomNavButtons
        previous="/mental-well-being"
        pageNum="1"
        isNextDisabled={false}
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
        next="/mental-well-being/work"
      />
    </>
  );
};

export default withRouter(Starts);
