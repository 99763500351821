import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import RecipientBox from 'go-phishing/components/RecipientBox';
import EmbeddedLink from 'go-phishing/components/EmbeddedLink';
import InfoSection from 'go-phishing/components/InfoSection';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import WarningSign from 'go-phishing/assets/images/warning-sign.svg';
import EmbeddedLinkImg from 'go-phishing/assets/images/embedded-link.svg';

const Email = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);
  const [answer, setAnswer] = useState('');

  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const { data } = props.location;
  const senderInfo = {
    name: 'Ali Majid',
    email: `Ali.Majid84@${data && data.orgName}.com`,
    subject: 'Is this you???',
    userEmail: data && data.userEmail,
  };
  const answerFeedback = {
    correct: 'Watch out! This is actually a phishing email. Hovering your cursor over the embedded link'
            + ' shows that this is directing you to a suspicious website that is trying to impersonate Onedrive.',
    wrong: 'Watch out! This is actually a phishing email. Hovering your cursor over the embedded link '
            + 'shows that this is directing you to a suspicious website that is trying to impersonate Onedrive.',
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                {
                    answer === 'legitimate'
                    && (
                    <div className="warning-sign">
                      <img alt="Warning" src={WarningSign} />
                    </div>
                    )
                }
                <div
                  className="target-link sender"
                  onMouseEnter={() => setShowSenderInfoBox(true)}
                  onMouseLeave={() => setShowSenderInfoBox(false)}
                >
                  { showSenderInfoBox && <RecipientBox senderInfo={senderInfo} /> }
                </div>

                <div
                  className="target-link email"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  { isHovering && <EmbeddedLink link="www.0n3drive.com" /> }
                </div>

                <img alt="Phishing Screen" src={EmbeddedLinkImg} />

              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={1}
            next="messenger"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="Your colleague just found a picture of you on the net!"
            helpText="Looks like you’re famous."
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(Email);
