import React from 'react';
import { withRouter } from 'react-router-dom';

import BaseComponent from 'ergonomics/components/BaseComponent';

import User from 'ergonomics/assets/images/user-feet.svg';
import UserCorrectImg from 'ergonomics/assets/images/user-20-rule.svg';
import WorkTable from 'ergonomics/assets/images/table-adjusted-work.svg';

const Feet = () => {
  const correctAssetsMap = {
    userCorrectImg: UserCorrectImg,
  };

  return (
    <>
      <div className="info-box-wrap">
        <BaseComponent
          beforeClicked="feet"
          afterClicked="feet-clicked"
          userClassName="user-feet-screen"
          pcClassName="pc-position-up"
          UserImg={User}
          CorrectAssetsMap={correctAssetsMap}
          PCImg={WorkTable}
          current="Feet"
          currentPage={4}
          nextLink="20-rule"
        />
      </div>
    </>
  );
};

export default withRouter(Feet);
