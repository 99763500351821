import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { COMPETENCE } from 'mental-wellbeing/actions';
import BaseComponent from 'mental-wellbeing/components/BaseComponent';

const Competence = ({ competenceData }) => {
  const initialState = {
    disruptsPrivateLife: '',
    utilizeKnowledge: '',
    softSkills: '',
    managementAcknowledged: '',
    managementAppreciated: '',
    constructiveCriticism: '',
  };
  const allQuestionMap = [
    {
      text: 'My job is so time-consuming or draining that it disrupts my private life',
      type: 'negative',
      name: 'disruptsPrivateLife',
      currentPage: 1,
    },
    {
      text: 'I am able to utilize my knowledge, skills, or expertise in my work',
      type: 'positive',
      name: 'utilizeKnowledge',
      currentPage: 1,
    },
    {
      text: 'I have the soft skills to do my job well', type: 'positive', name: 'softSkills', currentPage: 2,
    },
    {
      text: 'My efforts are acknowledged by the management',
      type: 'positive',
      name: 'managementAcknowledged',
      currentPage: 2,
    },
    {
      text: 'My efforts are appreciated by the management',
      type: 'positive',
      name: 'managementAppreciated',
      currentPage: 3,
    },
    {
      text: 'I am provided constructive criticism that helps me improve',
      type: 'positive',
      name: 'constructiveCriticism',
      currentPage: 3,
    },
  ];

  return (
    <BaseComponent
      maxPage={3}
      sectionPage={6}
      next="score"
      previous="belonging"
      type={COMPETENCE}
      current="competence"
      savedData={competenceData}
      initialState={initialState}
      allQuestionMap={allQuestionMap}
    />
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({ competenceData: mentalWellBeingReducer.competence });
}

export default connect(mapStateToProp)(withRouter(Competence));
