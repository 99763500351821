export default {
  BackPain: [
    { name: 'wrong', text: 'ergBackPainOptionOne' },
    { name: 'correct', text: 'ergBackPainOptionTwo' },
  ],
  Arms: [
    { name: 'wrong', text: 'ergArmsOptionOne' },
    { name: 'correct', text: 'ergArmsOptionTwo' },
  ],
  Neck: [
    { name: 'correct', text: 'ergNeckOptionOne' },
    { name: 'wrong', text: 'ergNeckOptionTwo' },
  ],
  Feet: [
    { name: 'wrong', text: 'ergFeetOptionOne' },
    { name: 'correct', text: 'ergFeetOptionTwo' },
  ],
  Rule20: [
    { name: 'correct', text: 'ergRule20OptionOne' },
    { name: 'wrong', text: 'ergRule20OptionTwo' },
  ],
};
