import React, { useEffect } from 'react';
import useSound from 'use-sound';

import WrongOptionSound from 'assets/sounds/wrong.wav';
import CorrectOptionSound from 'assets/sounds/correct.wav';

const FeedbackButtons = (props) => {
  const { answer, responseHandler, correctAnswer } = props;
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';
  const [playCorrectSound, correctExtra] = useSound(CorrectOptionSound);
  const [playWrongSound, wrongExtra] = useSound(WrongOptionSound);

  useEffect(() => {
    if (boolAnswer === 'correct' && answer !== '') {
      playCorrectSound();
    } else if (boolAnswer === 'wrong' && answer !== '') {
      playWrongSound();
    }

    return function cleanup() {
      correctExtra.stop();
      wrongExtra.stop();
    };
    // eslint-disable-next-line
    }, [answer, boolAnswer, playWrongSound, playCorrectSound]);

  const handleResponse = (ans) => {
    responseHandler(ans);
  };

  return (
    <div className="button-double">
      <span
        className={`btn ${answer === 'phishing' && `active-${boolAnswer}`}`}
        onClick={() => handleResponse('phishing')}
      >
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="12.316" height="12.312" viewBox="0 0 12.316 12.312">
            {/* eslint-disable-next-line max-len */}
            <path id="Union_93" data-name="Union 93" d="M9868.153,7827.566l-4.766,4.745-1.389-1.433,4.73-4.731-4.73-4.71,1.438-1.437,4.721,4.721,4.719-4.721,1.434,1.438-4.729,4.708,4.734,4.733-1.395,1.433Z" transform="translate(-9861.999 -7820)" fill="#69a1ac" />
          </svg>
        </i>
        <span>Phishing</span>
      </span>
      <span
        className={`btn ${answer === 'legitimate' && `active-${boolAnswer}`}`}
        onClick={() => handleResponse('legitimate')}
      >
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="18.061" height="13.751" viewBox="0 0 18.061 13.751">
            {/* eslint-disable-next-line max-len */}
            <path id="Icon_material-done" data-name="Icon material-done" d="M10.847,19.278l-4.31-4.31L5.1,16.4l5.747,5.747L23.161,9.837,21.724,8.4Z" transform="translate(-5.1 -8.4)" fill="#292c2e" />
          </svg>
        </i>
        <span>Legitimate</span>
      </span>
    </div>
  );
};

export default FeedbackButtons;
