import React from 'react';
import useSound from 'use-sound';
import { Link } from 'react-router-dom';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'common/components/HeaderNavigation/index.scss';

const NavigationLink = ({ tabPath, active, imgStart, imgStartHover, title, disabled }) => {
  const [play] = useSound(PageChangeSound);

  return (
    <Link
      to={tabPath}
      onClick={() => play()}
      className={`${disabled && 'disabled-link'} ${active && 'active'}`}
    >
      <span className="img-box">
        <span className="img">
          <img src={imgStart} alt={title} />
        </span>
        <span className="img-hover">
          <img src={imgStartHover} alt={title} />
        </span>
      </span>
      <span className="link-text">{title}</span>
    </Link>
  );
};

export default NavigationLink;
