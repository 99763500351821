import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { MOTIVATION } from 'mental-wellbeing/actions';
import BaseComponent from 'mental-wellbeing/components/BaseComponent';

const Motivation = ({ motivationData }) => {
  const initialState = {
    workProud: '',
    goalsAndAmbitions: '',
    personalAndProfessional: '',
    enjoyable: '',
    learningOpportunities: '',
    helpingCompany: '',
    jobSatisfied: '',
  };
  const allQuestionMap = [
    {
      text: 'I\'m proud of the work I do and it\'s an important part of who I am',
      type: 'positive',
      name: 'workProud',
      currentPage: 1,
    },
    {
      text: 'The work I do is aligned with my goals and ambitions',
      type: 'positive',
      name: 'goalsAndAmbitions',
      currentPage: 1,
    },
    {
      text: 'The work I do is aligned with my personal and professional values',
      type: 'positive',
      name: 'personalAndProfessional',
      currentPage: 2,
    },
    {
      text: 'I find my work enjoyable', type: 'positive', name: 'enjoyable', currentPage: 2,
    },
    {
      text: 'My job allows me new learning opportunities',
      type: 'positive',
      name: 'learningOpportunities',
      currentPage: 3,
    },
    {
      text: 'I am dedicated to helping my company and team succeed and grow',
      type: 'positive',
      name: 'helpingCompany',
      currentPage: 3,
    },
    {
      text: 'Overall, I am satisfied with my job',
      type: 'positive',
      name: 'jobSatisfied',
      currentPage: 4,
    },
  ];

  return (
    <BaseComponent
      maxPage={4}
      sectionPage={4}
      next="belonging"
      previous="autonomy"
      type={MOTIVATION}
      current="motivation"
      savedData={motivationData}
      initialState={initialState}
      allQuestionMap={allQuestionMap}
    />
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({ motivationData: mentalWellBeingReducer.motivation });
}

export default connect(mapStateToProp)(withRouter(Motivation));
