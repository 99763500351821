import React from 'react';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';

import Phishing from 'go-phishing';
import Ergonomics from 'ergonomics';
import NotFound from 'common/pages/404';
import EFCalculator from 'ef-calculator';
import MentalWellBeing from 'mental-wellbeing';

const App = () => (
  <div>
    <Router>
      <Switch>
        <Route path="/ef-calculator" component={EFCalculator} />
        <Route path="/mental-well-being" component={MentalWellBeing} />
        <Route path="/ergonomics" component={Ergonomics} />
        <Route path="/phishing" component={Phishing} />
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  </div>
);

export default withRouter(App);
