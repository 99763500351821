import { HighchartsChart, withHighcharts, XAxis, YAxis, PlotBand, ColumnSeries } from 'react-jsx-highcharts';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Highcharts from 'highcharts';
import useSound from 'use-sound';
import React from 'react';

import Header from 'mental-wellbeing/components/header';
import BottomNavButtons from 'common/components/BottomNavButtons';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const Score = (props) => {
  const [playNavChange] = useSound(PageChangeSound);

  const data = [
    { name: 'Work', impact: props.workImpact },
    { name: 'Autonomy', impact: props.autonomyImpact },
    { name: 'Motivation', impact: props.motivationImpact },
    { name: 'Belonging', impact: props.belongingImpact },
    { name: 'Competence', impact: props.competenceImpact },
  ];

  // eslint-disable-next-line prefer-spread
  const maxImpact = Math.max.apply(Math, data.map((o) => o.impact));
  const bands = [
    { label: 'Relative Concerns', from: 0, to: 10, bottomColor: '#C6FBBC', topColor: '#FFF795', id: 1 },
    { label: 'Minimal Concerns', from: 10, to: 20, bottomColor: '#FFF795', topColor: '#FFB586', id: 2 },
    { label: 'Significant Concerns', from: 20, to: 30, bottomColor: '#FFB586', topColor: '#FC957E', id: 3 },
    { label: 'Serious Concerns', from: 30, to: 100, bottomColor: '#FC957E', topColor: '#F27070', id: 4 },
  ];
  const yAxisMax = maxImpact < 30 ? 40 : maxImpact;

  const renderPlotBand = (band) => {
    const { from, to, id, bottomColor, topColor } = band;
    const colorGradient = {
      linearGradient: { x1: 1, y1: 1, x2: 1, y2: 0 },
      stops: [[0, bottomColor], [1, topColor]],
    };
    return (
      <PlotBand key={id} from={from} to={to} color={colorGradient} />
    );
  };

  return (
    <>
      <Header disableNext={false} />
      <main className="main">
        <div className="four-opt-wrapper default-wrapper">
          <HighchartsChart>
            <XAxis categories={data.map((d) => d.name)} />
            <YAxis softMax={yAxisMax}>
              <ColumnSeries
                borderWidth={2}
                color="#56B7F7"
                data={data.map((d) => d.impact)}
              />
              {bands.map(renderPlotBand)}
            </YAxis>
          </HighchartsChart>

          <div className="yAxis-labels">
            <span className="box relative" />
            <span>Relative Concerns</span>

            <span className="box minimal" />
            <span>Minimal Concerns</span>

            <span className="box significant" />
            <span>Significant Concerns</span>

            <span className="box serious" />
            <span>Serious Concerns</span>
          </div>
        </div>
      </main>
      <BottomNavButtons
        previous="/mental-well-being/competence"
        pageNum="7"
        isNextDisabled={false}
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
        next="/mental-well-being/action"
      />
    </>
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({
    workImpact: mentalWellBeingReducer.work.impact,
    autonomyImpact: mentalWellBeingReducer.autonomy.impact,
    motivationImpact: mentalWellBeingReducer.motivation.impact,
    belongingImpact: mentalWellBeingReducer.belonging.impact,
    competenceImpact: mentalWellBeingReducer.competence.impact,
  });
}

export default connect(mapStateToProp)(withRouter(withHighcharts(Score, Highcharts)));
