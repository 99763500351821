import { createReducer } from '@reduxjs/toolkit';
import {
  START, TRANSPORTATION, PAPER, ELECTRICITY, PLASTIC, WATER, FOOTPRINT, WATER_WASTE, WATER_GRAPH,
} from './actions';

const initialState = {
  start: {},
  transportation: {},
  paper: {},
  electricity: { electricInfo: {}, applianceCount: {} },
  plastic: {},
  water: { waterInfo: {}, shopInfo: {} },
  footprint: {},
  waterGraph: {},
  takeAction: {},
};

const efCalculatorReducer = createReducer(initialState, {
  [START]: (state, action) => ({ ...state, start: action.payload }),
  [TRANSPORTATION]: (state, action) => ({ ...state, transportation: action.payload }),
  [PAPER]: (state, action) => ({ ...state, paper: action.payload }),
  [ELECTRICITY]: (state, action) => ({ ...state, electricity: action.payload }),
  [PLASTIC]: (state, action) => ({ ...state, plastic: action.payload }),
  [WATER]: (state, action) => ({ ...state, water: action.payload }),
  [WATER_WASTE]: (state, action) => ({ ...state, waterWaste: action.payload }),
  [WATER_GRAPH]: (state, action) => ({ ...state, waterGraph: action.payload }),
  [FOOTPRINT]: (state, action) => ({ ...state, footprint: action.payload }),
});

export default efCalculatorReducer;
