import { Bar, BarChart, LabelList, XAxis, YAxis } from 'recharts';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Header from 'ef-calculator/components/header';
import { calculateWaterWasteImpact } from 'ef-calculator/utils/helper';
import { storeData, FOOTPRINT, WATER_GRAPH } from 'ef-calculator/actions';
import ChartBottomNav from 'ef-calculator/pages/footprint/ChartBottomNav';

import 'ef-calculator/pages/footprint/index.scss';

const CustomizedLabel = (props) => {
  const { x, y, width, value, carbonFootprint } = props;
  let offset = 20;
  if ((carbonFootprint > 40 && carbonFootprint < 61) || (carbonFootprint > 0.6 && carbonFootprint < 0.9)) {
    offset -= 5;
  } else if ((carbonFootprint > 60 && carbonFootprint < 101) || (carbonFootprint > 0.4 && carbonFootprint < 0.6)) {
    offset += 2;
  } else if (carbonFootprint === 0.6) {
    offset += 4;
  }
  return (<text x={x + width / 2} y={y} dy={offset} textAnchor="middle">{value}</text>);
};

const TonsFootprintLabel = (props) => {
  const { y, stroke, label, isFirst } = props;
  const textY = isFirst ? y + 24 : y;
  return (
    <svg>
      <svg x={-22} y={y} dy={-4} width="318" height="1.5" viewBox="0 0 250 1">
        <line x2="197" transform="translate(0 0.5)" fill="none" stroke="#464040" strokeWidth="1" strokeDasharray="7" />
      </svg>
      <text x={20} y={textY} dy={-4} fill={stroke} fontSize={12} textAnchor="center">
        {label}
      </text>
    </svg>
  );
};

const TonsGlobalLabel = (props) => {
  const { y, stroke, label, isFirst, isUrduLang } = props;
  const textY = isFirst ? y + 22 : y;
  const textX = isUrduLang ? 50 : 20;
  return (
    <svg>
      <svg x={-22} y={y} dy={-4} width="318" height="1.5" viewBox="0 0 250 1">
        <line x2="197" transform="translate(0 0.5)" fill="none" stroke="#464040" strokeWidth="1" strokeDasharray="7" />
      </svg>
      <text x={textX} y={textY} dy={-4} fill={stroke} fontSize={12} textAnchor="left">
        {label}
      </text>
    </svg>
  );
};

const GraphTick = (props) => (
  <g transform={`translate(${props.x},${props.y})`}>
    <text x={0} y={0} dy={16} textAnchor="middle" fill="#292C2E"> {props.label} </text>
  </g>
);

const getCarbonCharts = (carbonFP, globalLabel, footprintLabel, carbonChart) => {
  let carbonFootprint = carbonFP;
  let globalCo2 = 4.8;
  const TonsBarLabel = ''; // temp
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';
  const orgCarbonValue = carbonFootprint;

  let firstBarName = 'Your Footprint';
  let secondBarName = 'Global Average';
  let firstBarLabel = footprintLabel;
  let secondBarLabel = globalLabel;
  let FirstBarContent = TonsFootprintLabel;
  let SecondBarContent = TonsGlobalLabel;
  let secondBarColor = '#FFF';
  if (carbonFootprint > globalCo2) {
    firstBarName = 'Global Average';
    secondBarName = 'Your Footprint';
    firstBarLabel = globalLabel;
    secondBarLabel = footprintLabel;
    FirstBarContent = TonsGlobalLabel;
    SecondBarContent = TonsFootprintLabel;
    secondBarColor = '#CCBBA7';
    carbonFootprint -= globalCo2;
  } else {
    globalCo2 -= carbonFootprint;
  }
  const carbonDataMap = [{
    name: [TonsBarLabel],
    'Global Average': [globalCo2],
    'Your Footprint': [carbonFootprint],
  }];
  return (
    <BarChart
      width={300}
      height={270}
      data={carbonDataMap}
      ref={carbonChart}
      margin={{ top: 30, right: 10, left: 130, bottom: 5 }}
    >
      <YAxis type="number" orientation="right" />
      <XAxis tickLine={false} tick={<GraphTick label={TonsBarLabel} />} />
      <Bar name={firstBarName} dataKey={firstBarName} stackId="a" fill="#CCBBA7">
        <LabelList position="insideTop" content={<CustomizedLabel carbonFootprint={orgCarbonValue} />} />
        <LabelList
          position="top"
          content={<FirstBarContent isUrduLang={isUrduLang} label={firstBarLabel} isFirst />}
        />
      </Bar>
      <Bar dataKey={secondBarName} stackId="a" fill={secondBarColor}>
        <LabelList position="top" />
        <LabelList
          position="insideTopLeft"
          isUrduLang={isUrduLang}
          content={<SecondBarContent label={secondBarLabel} />}
        />
      </Bar>
    </BarChart>
  );
};

const GallonsFootprintLabel = (props) => {
  const { y, stroke, label, isFirst } = props;
  const textY = isFirst ? y + 22 : y;
  return (
    <svg>
      <svg x={70} y={y} dy={-4} width="318" height="1.5" viewBox="0 0 250 1">
        <line
          x2="197"
          transform="translate(0 0.5)"
          fill="none"
          stroke="#464040"
          strokeWidth="1"
          strokeDasharray="7"
        />
      </svg>
      <text x={180} y={textY} dy={-4} fill={stroke} fontSize={12} textAnchor="right">
        {label}
      </text>
    </svg>
  );
};

const GallonsGlobalLabel = (props) => {
  const { y, stroke, label, isFirst, isUrduLang } = props;
  const textY = isFirst ? y + 22 : y;
  const textX = isUrduLang ? 205 : 180;
  return (
    <svg>
      <svg x={70} y={y} dy={-4} width="318" height="1.5" viewBox="0 0 250 1">
        <line x2="197" transform="translate(0 0.5)" fill="none" stroke="#464040" strokeWidth="1" strokeDasharray="7" />
      </svg>
      <text x={textX} y={textY} dy={-4} fill={stroke} fontSize={12} textAnchor="right">
        {label}
      </text>
    </svg>
  );
};

const getGallonCharts = (waterFP, globalLabel, footprintLabel, waterChart) => {
  let waterFootprint = waterFP;
  let globalWaterConsumption = 146615;
  const WaterBarLabel = ''; // temp
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';

  let firstBarName = 'Your Footprint';
  let secondBarName = 'Global Average';
  let firstBarLabel = footprintLabel;
  let secondBarLabel = globalLabel;
  let FirstBarContent = GallonsFootprintLabel;
  let SecondBarContent = GallonsGlobalLabel;
  let secondBarColor = '#FFF';

  if (waterFootprint > globalWaterConsumption) {
    waterFootprint -= globalWaterConsumption;
    firstBarName = 'Global Average';
    secondBarName = 'Your Footprint';
    firstBarLabel = globalLabel;
    secondBarLabel = footprintLabel;
    FirstBarContent = GallonsGlobalLabel;
    SecondBarContent = GallonsFootprintLabel;
    secondBarColor = '#9BE6EF';
  } else {
    globalWaterConsumption -= waterFootprint;
  }
  const waterDataMap = [{
    name: [WaterBarLabel],
    'Global Average': [globalWaterConsumption],
    'Your Footprint': [waterFootprint],
  }];
  return (
    <BarChart
      width={300}
      height={270}
      data={waterDataMap}
      ref={waterChart}
      margin={{ top: 30, right: 130, left: 10, bottom: 5 }}
    >
      <YAxis type="number" orientation="left" tickLine={false} />
      <XAxis dataKey="name" tickLine={false} tick={<GraphTick label={WaterBarLabel} />} />
      <Bar dataKey={firstBarName} padding="0" stackId="b" fill="#9BE6EF">
        <LabelList position="insideTop" value="any" />
        <LabelList
          position="insideTopLeft"
          content={<FirstBarContent isUrduLang={isUrduLang} label={firstBarLabel} isFirst />}
        />
      </Bar>
      <Bar name={secondBarName} dataKey={secondBarName} stackId="b" fill={secondBarColor}>
        <LabelList position="top" value="any" />
        <LabelList
          position="insideTop"
          orientation="right"
          content={<SecondBarContent isUrduLang={isUrduLang} label={secondBarLabel} />}
        />
      </Bar>
    </BarChart>
  );
};

const Footprint = (props) => {
  const { t } = useTranslation();
  const globalLabel = t('FpGlobalLabel');
  const footprintLabel = t('FpYourFpLabel');
  const lang = localStorage.getItem('i18nextLng');
  const charts = { carbon: React.createRef(), water: React.createRef() };

  const [waterImpact, setWaterImpact] = useState(0);
  const [carbonFootprint, setCarbonFootprint] = useState(0);

  const exportWaterGraph = (chart) => {
    const chartSVG = chart.current.container.children[0];
    const yAxisLabels = chartSVG.getElementsByClassName('yAxis')[0];
    const yAxisTicks = yAxisLabels.getElementsByClassName('recharts-cartesian-axis-ticks')[0];
    yAxisLabels.removeChild(yAxisTicks);

    const width = chartSVG.clientWidth;
    const height = chartSVG.clientHeight;
    const svgURL = new XMLSerializer().serializeToString(chartSVG);
    const svgBlob = new Blob([svgURL], { type: 'image/svg+xml;charset=utf-8' });
    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svgBlob);
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
      const pngImage = canvas.toDataURL('image/png', 1.0);
      props.storeData({ chart: pngImage, actionType: WATER_GRAPH });
      props.history.push('/ef-calculator/take-action');
    };
    image.src = blobURL;
  };

  const exportCarbonCharts = (carbonChart) => {
    const chartSVG = carbonChart.current.container.children[0];
    const yAxisLabels = chartSVG.getElementsByClassName('yAxis')[0];
    const yAxisTicks = yAxisLabels.getElementsByClassName('recharts-cartesian-axis-ticks')[0];
    yAxisLabels.removeChild(yAxisTicks);

    const width = chartSVG.clientWidth;
    const height = chartSVG.clientHeight;
    const svgURL = new XMLSerializer().serializeToString(chartSVG);
    const svgBlob = new Blob([svgURL], { type: 'image/svg+xml;charset=utf-8' });
    const URL = window.URL || window.webkitURL || window;
    const blobURL = URL.createObjectURL(svgBlob);
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement('canvas');
      canvas.width = width;
      canvas.height = height;
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0, context.canvas.width, context.canvas.height);
      const carbonChartImg = canvas.toDataURL('image/png', 1.0);
      props.storeData({ chart: carbonChartImg, actionType: FOOTPRINT });
    };
    image.src = blobURL;
  };

  const exportCharts = (chartsMap) => {
    exportCarbonCharts(chartsMap.carbon);
    exportWaterGraph(chartsMap.water);
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      let carbonFP = props.transportationData.impact + props.paperData.impact
        + props.electricityData.impact + props.plasticData.impact;
      const waterImpactValue = calculateWaterWasteImpact(props.waterData.impactInfo, props.waterWasteData);

      if (!Number.isInteger(carbonFP) && carbonFP > 0 && carbonFP < 100) {
        carbonFP = Number.isInteger(carbonFP) ? carbonFP
          : carbonFP.toFixed(1 - Math.floor(Math.log(carbonFP) / Math.log(10)));
      } else {
        carbonFP = Math.round(carbonFP);
      }
      setCarbonFootprint(carbonFP);
      setWaterImpact(waterImpactValue);
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  return (
    <>
      <Header disableNext={false} />

      <main className="main">
        <div className="default-wrapper footprint">
          <div className="form-row">
            <h2>{t('FpGraphTitle')}</h2>
          </div>
        </div>

        <div className="footprint-block">
          <div className="column">
            <h3 className={`recharts-wrapper graph-heading carbon-${lang}`}>{t('FpTonsBarLabel')}</h3>
            {getCarbonCharts(carbonFootprint, globalLabel, footprintLabel, charts.carbon)}
          </div>

          <div className="column">
            <h3 className={`recharts-wrapper graph-heading water-${lang}`}>{t('FpWaterBarLabel')}</h3>
            {getGallonCharts(waterImpact, globalLabel, footprintLabel, charts.water)}
          </div>
        </div>

      </main>

      <ChartBottomNav nextHandler={() => exportCharts(charts)} />
    </>

  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({
    transportationData: efCalculatorReducer.transportation,
    paperData: efCalculatorReducer.paper,
    electricityData: efCalculatorReducer.electricity,
    plasticData: efCalculatorReducer.plastic,
    waterData: efCalculatorReducer.water,
    waterWasteData: efCalculatorReducer.waterWaste,
  });
}

export default connect(mapStateToProp, { storeData })(withRouter(Footprint));
