import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, withRouter,
} from 'react-router-dom';

import Final from 'common/pages/final';
import NotFound from 'common/pages/404';
import BankSms from 'go-phishing/pages/bank-sms';
import Email from 'go-phishing/pages/email/index';
import OneDrive from 'go-phishing/pages/onedrive';
import BadReply from 'go-phishing/pages/bad-reply';
import Messenger from 'go-phishing/pages/messenger';
import GetStarted from 'common/pages/get-started/index';
import Instructions from 'go-phishing/pages/instructions';
import ExcelDownload from 'go-phishing/pages/excel-download';
import PasswordTheft from 'go-phishing/pages/password-theft';
import Logo from 'go-phishing/assets/images/phishing-logo.svg';
import OutsideCompany from 'go-phishing/pages/outside-company';
import ConsentPhishing from 'go-phishing/pages/consent-phishing';

const Phishing = () => (
  <Router>
    <Switch>
      <Route path="/phishing" exact>
        <GetStarted
          title="Phishing"
          next="/phishing/instructions"
          logo={Logo}
        />
      </Route>
      <Route>
        <div className="wrapper landing">
          <div className="w1">
            <Switch>
              <Route path="/phishing/instructions" component={Instructions} />
              <Route path="/phishing/email" component={Email} />
              <Route path="/phishing/messenger" component={Messenger} />
              <Route path="/phishing/bank-sms" component={BankSms} />
              <Route path="/phishing/consent-phishing" component={ConsentPhishing} />
              <Route path="/phishing/bad-reply" component={BadReply} />
              <Route path="/phishing/outside-company" component={OutsideCompany} />
              <Route path="/phishing/excel-download" component={ExcelDownload} />
              <Route path="/phishing/onedrive" component={OneDrive} />
              <Route path="/phishing/password-theft" component={PasswordTheft} />
              <Route path="/phishing/final" component={Final} />
              <Route path="/phishing/*" component={NotFound} />
            </Switch>
          </div>
        </div>
      </Route>
    </Switch>
  </Router>
);

export default withRouter(Phishing);
