import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { BELONGING } from 'mental-wellbeing/actions';
import BaseComponent from 'mental-wellbeing/components/BaseComponent';

const Belonging = ({ belongingData }) => {
  const initialState = {
    countOnColleagues: '',
    countOnLeads: '',
    kindness: '',
    disputesOrTensions: '',
    resolvingConflicts: '',
    trustManagement: '',
  };
  const allQuestionMap = [
    {
      text: 'I can count on my colleagues and coworkers for help and support when I need it',
      type: 'positive',
      name: 'countOnColleagues',
      currentPage: 1,
    },
    {
      text: 'I can count on my team leads and managers for help and support when I need it',
      type: 'positive',
      name: 'countOnLeads',
      currentPage: 1,
    },
    {
      text: 'At our organization, we treat each other with kindness and respect without any discrimination',
      type: 'positive',
      name: 'kindness',
      currentPage: 2,
    },
    {
      text: 'There are disputes or tensions among my colleagues',
      type: 'negative',
      name: 'disputesOrTensions',
      currentPage: 2,
    },
    {
      text: 'My management does a good job at resolving any conflicts that may arise',
      type: 'positive',
      name: 'resolvingConflicts',
      currentPage: 3,
    },
    {
      text: 'I can trust my management with the policies, processes, and practices '
				+ 'that are communicated to me',
      type: 'positive',
      name: 'trustManagement',
      currentPage: 3,
    },
  ];

  return (
    <BaseComponent
      maxPage={3}
      sectionPage={4}
      next="competence"
      previous="motivation"
      type={BELONGING}
      current="belonging"
      savedData={belongingData}
      initialState={initialState}
      allQuestionMap={allQuestionMap}
    />
  );
};

function mapStateToProp({ mentalWellBeingReducer }) {
  return ({ belongingData: mentalWellBeingReducer.belonging });
}

export default connect(mapStateToProp)(withRouter(Belonging));
