import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';
import useSound from 'use-sound';
import i18next from 'i18next';

import FinalImage from 'assets/images/final-image.svg';
import StartScreenSound from 'assets/sounds/final.wav';

const Final = () => {
  const [play, { stop }] = useSound(StartScreenSound);
  const langDir = i18next.dir();
  const { t } = useTranslation();

  useEffect(() => {
    play();
    return function cleanup() {
      stop();
    };
  }, [play, stop]);

  return (
    <div className="info-box-wrap" dir={langDir}>
      <div className="final-message">
        <h3>{t('allDone')}</h3>
        <p>{t('congratulationMsg')}</p>
        <img alt="Final Screen" src={FinalImage} />
        <span className="info">{t('exitText')}</span>
      </div>
    </div>
  );
};

export default Final;
