/* eslint-disable no-case-declarations */
import React, { useReducer, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { TRANSPORTATION, storeData } from 'ef-calculator/actions';
import { calculateTransportationCarbonEmission } from 'ef-calculator/utils/helper';

import { ReactComponent as ImgWorkFromHome } from 'ef-calculator/assets/images/work-from-home.svg';
import { ReactComponent as ImgMotorcycle } from 'ef-calculator/assets/images/motorcycle.svg';
import { ReactComponent as ImgCarHybrid } from 'ef-calculator/assets/images/car-hybrid.svg';
import { ReactComponent as ImgRickshaw } from 'ef-calculator/assets/images/rickshaw.svg';
import { ReactComponent as ImgBicycle } from 'ef-calculator/assets/images/bicycle.svg';
import { ReactComponent as ImgFeet } from 'ef-calculator/assets/images/feet.svg';
import { ReactComponent as ImgCar } from 'ef-calculator/assets/images/car.svg';
import { ReactComponent as ImgBus } from 'ef-calculator/assets/images/bus.svg';
import BottomNavButtons from 'common/components/BottomNavButtons';
import NumericInput from 'common/components/NumericInput';
import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonSound from 'assets/sounds/button.mp3';

const reducer = (state, action) => {
  switch (action.type) {
    case 'vehicleChange':
      const distance = action.vehicle === 'wfh' ? '' : state.commuteDistance;
      return { ...state, vehicle: action.vehicle, commuteDistance: distance };
    case 'setCommuteDistance': return { ...state, commuteDistance: action.commuteDistance };
    case 'update': return { ...state, ...action.payload };
    default: throw new Error('Unexpected action');
  }
};

const Transportation = (props) => {
  const [playButtonSound] = useSound(ButtonSound);
  const [playNavChange] = useSound(PageChangeSound);
  const componentWillUnmount = useRef(false);
  const [state, dispatch] = useReducer(reducer, { commuteDistance: '', vehicle: '' });
  const { vehicle, commuteDistance } = state;
  const { t } = useTranslation();

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      const impact = calculateTransportationCarbonEmission(vehicle, commuteDistance);
      // eslint-disable-next-line no-console
      console.log(`transport:  ${impact}`); // temp
      props.storeData({
        impact,
        vehicle,
        actionType: TRANSPORTATION,
        commuteDistance,
      });
    }
  }
  // eslint-disable-next-line
	, [vehicle, commuteDistance])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({
        type: 'update',
        payload: {
          vehicle: props.transportationData.vehicle || vehicle,
          commuteDistance: props.transportationData.commuteDistance || commuteDistance,
        },
      });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isNextDisabled = () => {
    if (vehicle === 'wfh') {
      return false;
    }
    return !(commuteDistance && vehicle);
  };

  const isCommuteDistanceDisabled = () => vehicle === '' || vehicle === 'wfh';

  const handleChange = (e) => {
    const newCommuteDistance = e.target.validity.valid ? e.target.value : commuteDistance;
    dispatch({ type: 'setCommuteDistance', commuteDistance: newCommuteDistance });
  };

  const renderInput = () => (
    <div className="form-row">
      <h2>{t('transportWorkplaceQT')}</h2>
      <NumericInput
        value={commuteDistance}
        text={t('transportWorkplaceInputLabel')}
        handleInputChange={handleChange}
        maxLength="3"
      />
    </div>
  );

  const optionsMap = [
    { name: 'car', textKey: 'transportCar', image: <ImgCar /> },
    { name: 'hybridcar', textKey: 'transportHCar', image: <ImgCarHybrid /> },
    { name: 'bus', textKey: 'transportBus', image: <ImgBus /> },
    { name: 'bicycle', textKey: 'transportBicycle', image: <ImgBicycle /> },
    { name: 'motorcycle', textKey: 'transportMCycle', image: <ImgMotorcycle /> },
    { name: 'rickshaw', textKey: 'transportRickshaw', image: <ImgRickshaw /> },
    { name: 'feet', textKey: 'transportFeet', image: <ImgFeet /> },
    { name: 'wfh', textKey: 'transportWFH', image: <ImgWorkFromHome /> },
  ];

  return (
    <>
      <Header disableNext={isNextDisabled()} />
      <main className="main">
        <div className="default-wrapper four-opt-wrapper">
          <div className="form-row">
            <h2>{t('transportModeQT')}</h2>
            <ul className="buttons-list">
              {
								optionsMap.map((option) => (
  <li key={option.name} className="four-opt">
    <div className="radio-label">
      <input
        type="radio"
        className="custom-control-input"
        name="transport"
        id={`transport-${option.name}`}
        checked={vehicle === option.name}
        onChange={(e) => {
          playButtonSound();
          dispatch({ type: 'vehicleChange', vehicle: e.target.value });
        }}
        value={option.name}
      />
      <label
        htmlFor={`transport-${option.name}`}
        className="custom-control-label"
      >
        <span className="image-box">{option.image}</span>
        <span className="text">{t(option.textKey)}</span>
      </label>
    </div>
  </li>
								))
							}
            </ul>
          </div>
          {
						!isCommuteDistanceDisabled() && renderInput()
					}
        </div>
      </main>

      <BottomNavButtons
        pageNum="2"
        next="/ef-calculator/paper"
        previous="/ef-calculator/start"
        onBackClick={() => { playNavChange(); }}
        onNextClick={() => { playNavChange(); }}
        isNextDisabled={isNextDisabled()}
      />
    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ transportationData: efCalculatorReducer.transportation });
}

export default connect(mapStateToProp, { storeData })(withRouter(Transportation));
