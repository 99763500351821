/* eslint-disable no-tabs,no-mixed-spaces-and-tabs,radix,no-nested-ternary,no-case-declarations */
import React, { useRef, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { ELECTRICITY, storeData } from 'ef-calculator/actions';
import BottomNavButtons from 'common/components/BottomNavButtons';
import NumberInputButtons from 'common/components/NumberInputButtonsField';
import { calculateElectricCarbonEmission } from 'ef-calculator/utils/helper';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'ef-calculator/pages/electricity/index.scss';
import ButtonSound from 'assets/sounds/button.mp3';

const reducer = (state, action) => {
  let key = null;
  switch (action.type) {
    case 'TonDecrement':
      return { ...state, [action.key]: state[action.key] > 1 ? state[action.key] - 0.5 : state[action.key] };
    case 'TonIncrement':
      return { ...state, [action.key]: state[action.key] < 2 ? state[action.key] + 0.5 : state[action.key] };
    case 'Decrement':
      return { ...state, [action.key]: state[action.key] > 0 ? parseInt(state[action.key]) - 1 : state[action.key] };
    case 'Increment':
      key = action.key;
      return { ...state, [key]: parseInt(state[key]) + 1 > 99 ? parseInt(state[key]) : parseInt(state[key]) + 1 };
    case 'onChange':
      key = action.e.target.name;
      const { value } = action.e.target;
      return { ...state, [key]: parseInt((action.e.target.validity.valid) ? (value === '' ? 0 : value) : state[key]) };

    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error('Unexpected action');
  }
};

const ElectricityAppliances = (props) => {
  const initialState = {
    tubeLights: 0,
    esTubeLights: 0,
    waterDispenser: 0,
    cellingFan: 0,
    inkJetPrinter: 0,
    laserPrinter: 0,
    computerAcc: 0,
    laptop: 0,
    heater: 0,
    ac: 0,
    inverterAC: 0,
    acT: 1,
    inverterACT: 1,
  };

  const { t } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);
  const componentWillUnmount = useRef(false);

  const [playNavChange] = useSound(PageChangeSound);
  const [playButtonSound] = useSound(ButtonSound);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
    	const impact = calculateElectricCarbonEmission(state, props.electricityData.electricInfo);
      // eslint-disable-next-line no-console
      console.log(`electric:  ${impact}`); // temp
      props.storeData({
        ...state,
        impact,
        applianceCount: state,
        actionType: ELECTRICITY,
        electricInfo: props.electricityData.electricInfo,
      });
    }
  }
  // eslint-disable-next-line
	, [state, props.electricityData.electricInfo])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({ type: 'update', payload: props.electricityData.applianceCount });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const isDisableNext = () => {
    const {
      tubeLights, esTubeLights, waterDispenser, cellingFan,
      inkJetPrinter, laserPrinter, computerAcc, laptop, heater, ac, inverterAC,
    } = state;
    const sumAppliances = tubeLights + esTubeLights + waterDispenser + cellingFan + inkJetPrinter
			+ laserPrinter + computerAcc + laptop + heater + ac + inverterAC;
    return sumAppliances < 1;
  };

  const renderApplianceInputs = () => {
    const fields = [];
    const applianceMap = {
      tubeLights: t('applianceTubeLight'),
      esTubeLights: t('applianceESLB'),
      waterDispenser: t('applianceWaterDispenser'),
      cellingFan: t('applianceCeilingFan'),
      inkJetPrinter: t('applianceInkjetPrinter'),
      laserPrinter: t('applianceLaserPrinter'),
      computerAcc: t('applianceComputer'),
      laptop: t('applianceLaptop'),
      heater: t('applianceHeater'),
    };
    // eslint-disable-next-line no-restricted-syntax
    for (const [name, text] of Object.entries(applianceMap)) {
      fields.push(
        <NumberInputButtons
          key={name}
          value={state[name]}
          name={name}
          text={text}
          handleOnChange={(e) => dispatch({ type: 'onChange', e })}
          handleDecrement={() => { playButtonSound(); dispatch({ type: 'Decrement', key: name }); }}
          handleIncrement={() => { playButtonSound(); dispatch({ type: 'Increment', key: name }); }}
        />,
      );
    }
    return fields;
  };

  return (
    <>
      <Header disableNext={isDisableNext()} />

      <main className="main">
        <div className="default-wrapper electricity-page appliances">
          <div className="form-row">
            <h2>{t('applianceCountQT')}</h2>

            <ul className="buttons-list">
              {renderApplianceInputs()}

              <li>
                <div className="data-counter button-value">
                  <div className="counter">
                    <span
                      className="count decrement"
                      onClick={() => {
	                    	playButtonSound();
		                    dispatch({ type: 'TonDecrement', key: 'acT' });
	                    }}
                    >
                      -
                    </span>
                    <span className="input-frame">
                      <input value={state.acT} readOnly />
                    </span>
                    <span
                      className="count increment"
                      onClick={() => {
											  playButtonSound();
											  dispatch({ type: 'TonIncrement', key: 'acT' });
                      }}
                    >
                      +
                    </span>
                  </div>
                  <span className="text-label">
                    {t('applianceTonAC')}
                  </span>
                  <div className="counter">
                    <span
                      className="count decrement"
                      onClick={() => {
	                    	playButtonSound();
		                    dispatch({ type: 'Decrement', key: 'ac' });
	                    }}
                    >
                      -
                    </span>
                    <span className="input-frame">
                      <input
                        value={state.ac}
                        pattern="[0-9]*"
                        maxLength="2"
                        name="ac"
                        onChange={(e) => dispatch({ type: 'onChange', e })}
                      />
                    </span>
                    <span
                      className="count increment"
                      onClick={() => {
										      	playButtonSound();
											      dispatch({ type: 'Increment', key: 'ac' });
										      }}
                    >
                      +
                    </span>
                  </div>
                </div>
              </li>
              <li>
                <div className="data-counter button-value">
                  <div className="counter">
                    <span
                      className="count decrement"
                      onClick={() => {
                          	playButtonSound();
	                          dispatch({ type: 'TonDecrement', key: 'inverterACT' });
                      }}
                    >
                      -
                    </span>
                    <span className="input-frame">
                      <input readOnly value={state.inverterACT} />
                    </span>
                    <span
                      className="count increment"
                      onClick={() => {
										      	playButtonSound();
											      dispatch({ type: 'TonIncrement', key: 'inverterACT' });
										      }}
                    >
                      +
                    </span>
                  </div>
                  <span className="text-label">{t('applianceInvTonAC')}</span>
                  <div className="counter">
                    <span
                      className="count decrement"
                      onClick={() => {
                          	playButtonSound();
	                          dispatch({ type: 'Decrement', key: 'inverterAC' });
                      }}
                    >
                      -
                    </span>
                    <span className="input-frame">
                      <input
                        value={state.inverterAC}
                        pattern="[0-9]*"
                        maxLength="2"
                        name="inverterAC"
                        onChange={(e) => dispatch({ type: 'onChange', e })}
                      />
                    </span>
                    <span
                      className="count increment"
                      onClick={() => {
											  playButtonSound();
											  dispatch({ type: 'Increment', key: 'inverterAC' });
                      }}
                    >
                      +
                    </span>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>
      </main>

      <BottomNavButtons
        pageNum="4"
        next="/ef-calculator/plastic"
        isNextDisabled={isDisableNext()}
        previous="/ef-calculator/electricity"
        onBackClick={() => playNavChange()}
        onNextClick={() => playNavChange()}
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ electricityData: efCalculatorReducer.electricity });
}

export default connect(mapStateToProp, { storeData })(withRouter(ElectricityAppliances));
