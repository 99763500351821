import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import InfoSection from 'go-phishing/components/InfoSection';
import RecipientBox from 'go-phishing/components/RecipientBox';
import EmbeddedLink from 'go-phishing/components/EmbeddedLink';

import 'ergonomics/assets/scss/components/_info-box.scss';
import 'ergonomics/assets/scss/components/_check-list.scss';
import 'go-phishing/assets/sass/components/_phishing-styles.scss';
import WarningSign from 'go-phishing/assets/images/warning-sign.svg';
import OutsideCompanyImg from 'go-phishing/assets/images/outside-company.svg';

const OutsideCompany = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  const [showSenderInfoBox, setShowSenderInfoBox] = useState(false);
  const [answer, setAnswer] = useState('');

  const { data } = props.location;
  const correctAnswer = 'phishing';
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  const answerFeedback = {
    correct: 'This is not a legitimate email! The sender of the email does not belong to your company, '
            + 'and the Google Form link actually leads to a fake website: http://gogle.form.com/',
    wrong: 'This is not a legitimate email! The sender of the email does not belong to your company, '
            + 'and the Google Form link actually leads to a fake website: http://gogle.form.com/',
  };
  const senderInfo = {
    name: 'Sania Arshad',
    email: `Sania.Arshad12@not-${data && data.orgName}.com`,
    subject: 'URGENT: Client Data',
    userEmail: data && data.userEmail,
  };

  return (
    <>
      <div className="info-box-wrap">
        <div className="row">
          <div className="col-image">
            <div className="image-wrap cursor-reset">

              <div className="blue-bg">
                {
                    answer === 'legitimate'
                    && (
                    <div className="warning-sign company-sender">
                      <img alt="Warning" src={WarningSign} />
                    </div>
                    )
                }
                <div
                  className="target-link sender"
                  onMouseEnter={() => setShowSenderInfoBox(true)}
                  onMouseLeave={() => setShowSenderInfoBox(false)}
                >
                  {
                    showSenderInfoBox
                    && <RecipientBox senderInfo={senderInfo} />
                  }
                </div>
                {
                    answer === 'legitimate'
                    && (
                    <div className="warning-sign company-form">
                      <img alt="Warning" src={WarningSign} />
                    </div>
                    )
                }

                <img alt="Phishing Screen" src={OutsideCompanyImg} />

                <div
                  className="target-link g-form"
                  onMouseEnter={() => setIsHovering(true)}
                  onMouseLeave={() => setIsHovering(false)}
                >
                  { isHovering && <EmbeddedLink link="http://gogle.form.com/" /> }
                </div>

              </div>

            </div>
          </div>

          <InfoSection
            answer={answer}
            currentPage={6}
            next="excel-download"
            correctAnswer={correctAnswer}
            handleResponse={(ans) => setAnswer(ans)}
            feedbackText={answerFeedback[boolAnswer]}
            heading="You only have 30 minutes!"
            helpText="What is this, pizza delivery?"
          />

        </div>
      </div>
    </>
  );
};

export default withRouter(OutsideCompany);
