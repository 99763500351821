import React from 'react';

const NumberInputButtonsField = (props) => {
  function handleInputChange(event) {
    props.handleOnChange(event);
  }

  return (
    <li>
      <div className="data-counter">
        <span className="text-label">{props.text}</span>
        <div className="counter">
          <span
            className="count decrement"
            onClick={() => props.handleDecrement()}
          >-
          </span>
          <span className="input-frame">
            <input
              value={props.value}
              pattern="[0-9]*"
              name={props.name}
              maxLength="2"
              onChange={handleInputChange.bind(this)}
            />
          </span>
          <span
            className="count increment"
            onClick={() => props.handleIncrement()}
          >+
          </span>
        </div>
      </div>
    </li>
  );
};

export default NumberInputButtonsField;
