import React from 'react';

const NotFound = () => (
  <div className="main">
    <div className="default-wrapper">
      <h1>404 Error</h1>
      <p>
        Sorry, the page you were looking for could not be found.
        Please check to see if you have typed the correct address.
      </p>
    </div>
  </div>
);

export default NotFound;
