import { Link, withRouter } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import { WORK, storeData } from 'ergonomics/actions';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import 'ergonomics/assets/scss/components/_card-select.scss';
import { ReactComponent as HomeChair } from 'ergonomics/assets/images/chair-home.svg';
import { ReactComponent as OfficeChair } from 'ergonomics/assets/images/chair-office.svg';

const Work = (props) => {
  const [workFrom, setWorkFrom] = useState('');
  const [play] = useSound(PageChangeSound);
  const { t } = useTranslation();

  useEffect(() => {
    setWorkFrom(props.workData.workFrom || workFrom);
    return function cleanup() {
      props.storeData({ actionType: WORK, workFrom });
    };
    // eslint-disable-next-line
    }, [workFrom]);

  return (
    <>
      <h2>{t('workingFrom')}</h2>

      <div className="card-wrap">
        <div className={`card ${workFrom === 'home' && 'active'}`}>
          <span onClick={() => setWorkFrom('home')}>
            <HomeChair />
            <p>{t('home')}</p>
          </span>
        </div>
        <div className={`card ${workFrom === 'office' && 'active'}`}>
          <span onClick={() => setWorkFrom('office')}>
            <OfficeChair />
            <p>{t('office')}</p>
          </span>
        </div>
      </div>

      <Link
        className={`btn btn-primary ${workFrom ? '' : 'disabled disabled-link'}`}
        to="/ergonomics/info"
        onClick={() => play()}
      >
        {t('next')}
      </Link>

    </>
  );
};

function mapStateToProp({ ergonomicsReducer }) {
  return ({ workData: ergonomicsReducer.work });
}

export default connect(mapStateToProp, { storeData })(withRouter(Work));
