/* eslint-disable react/no-unescaped-entities */
import { Document, Image, Page, Text, StyleSheet, View, Font } from '@react-pdf/renderer';
import React from 'react';

import { useTranslation } from 'react-i18next';

import PakXLogo from 'assets/images/logo.png';
import CarbonLabelImg from 'ef-calculator/assets/images/co2-label.png';
import WaterLabelImg from 'ef-calculator/assets/images/water-label.png';
import GraphHeading from 'ef-calculator/assets/images/graph-heading.png';
import ActionHeading from 'ef-calculator/assets/images/action-heading.png';

import EbGaramond from 'assets/fonts/EBGaramond-Bold.ttf';
import HkGroteskBold from 'assets/fonts/hkgrotesk-bold-webfont.ttf';
import Nastaliq from 'assets/fonts/fajer-noori-nastalique-regular.ttf';
import HkGroteskRegular from 'assets/fonts/hkgrotesk-regular-webfont.ttf';

Font.register({ family: 'EbGaramond', src: EbGaramond });
Font.register({ family: 'HkGroteskRegular', src: HkGroteskRegular });
Font.register({ family: 'HkGroteskBold', src: HkGroteskBold });
Font.register({ family: 'nastaliq', src: Nastaliq });

const PdfReport = (props) => {
  const { textImage, chartImages, userName } = props;

  const { t } = useTranslation();
  const isUrduLang = localStorage.getItem('i18nextLng') === 'ur';

  const styles = StyleSheet.create({
    page: {
      size: 'A4',
    },
    wrapper: {
      paddingTop: '20px',
      paddingLeft: '40px',
      paddingRight: '40px',

      borderTop: 20,
      borderTopColor: '#8BC5CB',
    },
    graphWrapper: {
      flexDirection: 'row',
    },
    column: {
      flex: 1,
      display: 'flex',
      marginTop: '10px',
      justifyContent: 'flex-end',
    },
    Heading1: {
      fontSize: 24,
      color: '#2e2e2e',
      fontWeight: 'bold',
      fontFamily: 'EbGaramond',
    },
    Heading1Urdu: {
      fontSize: 24,
      color: '#2e2e2e',
      textAlign: 'right',
      fontWeight: 'bold',
      fontFamily: 'nastaliq',
    },
    Heading2: {
      fontSize: 18,
      color: '#8BC5CB',
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'HkGroteskBold',
    },
    Heading2Urdu: {
      fontSize: 18,
      color: '#8BC5CB',
      fontWeight: 'bold',
      textAlign: 'center',
      fontFamily: 'nastaliq',
    },
    urdu: {
      fontSize: 24,
      color: '#2e2e2e',
      paddingRight: 10,
      textAlign: 'right',
      fontFamily: 'nastaliq',
    },
    actionWrapper: {
      flex: 1,
      display: 'flex',
      marginTop: '10px',
    },
    graphImage: {
      width: '160px',
      height: '170px',
    },
    textImage: {
      width: '100%',
      height: '320px',
      justifyContent: 'flex-start',
    },
    textImageEng: {
      width: '100%',
      height: '380px',
      marginTop: '20px',
      justifyContent: 'flex-start',
    },
    graphHeadingImg: {
      width: '100%',
    },
    bottomFooter: {
      bottom: 0,
      width: '100%',
      height: '50px',
      display: 'flex',
      position: 'absolute',
      backgroundColor: '#f2f2f2',
    },
    bottomLogo: {
      width: '100px',
      paddingTop: '10px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
    },
  });

  return (
    <Document>
      <Page size="A4">
        <View style={styles.wrapper} fixed>
          { isUrduLang
            ? (
              <View>
                <Image src={GraphHeading} style={styles.graphHeadingImg} allowDangerousPaths />
              </View>
            )
            : (
              <Text style={[{ marginBottom: '10px', marginTop: '10px' }, styles.Heading1]}>
                {t('pdfGraphTitle', { name: userName })}
              </Text>
            )}
          <View style={styles.graphWrapper}>
            <View style={styles.column}>
              { isUrduLang
                ? (
                  <Image src={CarbonLabelImg} allowDangerousPaths />
                )
                : (
                  <Text style={styles.Heading2}>
                    {t('pdfCo2Label')}
                  </Text>
                )}
              <View style={[{ display: 'flex' }]}>

                <Image
                  style={[{ marginLeft: 'auto' }, styles.graphImage]}
                  allowDangerousPaths
                  src={chartImages.carbonChartImg}
                />
              </View>
            </View>
            <View style={[{ marginRight: '20px' }, styles.column]}>
              { isUrduLang
                ? (
                  <Image style={{ marginLeft: '30px' }} src={WaterLabelImg} allowDangerousPaths />
                )
                : (
                  <Text style={[styles.Heading2]}>
                    {t('pdfWaterLabel')}
                  </Text>
                )}
              <Image
                style={[{ marginRight: 'auto' }, styles.graphImage]}
                allowDangerousPaths
                src={chartImages.waterChartImg}
              />
            </View>
          </View>

          <View style={styles.actionWrapper}>
            { isUrduLang
              ? (
                <Image src={ActionHeading} allowDangerousPaths />
              )
              : (
                <Text style={styles.Heading1}>
                  {t('pdfActionTextTitle')}
                </Text>
              )}
            <View style={{ marginTop: '5px', paddingRight: '5px' }}>
              {
                isUrduLang
                  ? (
                    <Image style={styles.textImage} src={textImage} allowDangerousPaths />
                  )
                  : (
                    <Image style={styles.textImageEng} src={textImage} allowDangerousPaths />
                  )
              }
            </View>
          </View>
        </View>
        <View style={styles.bottomFooter}>
          <Image style={styles.bottomLogo} src={PakXLogo} />
        </View>
      </Page>
    </Document>
  );
};

export default PdfReport;
