import { withRouter, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'mental-wellbeing/components/header';
import Question from 'mental-wellbeing/components/question';
import BottomNavButtons from 'common/components/BottomNavButtons';
import { calculateImpact, storeData } from 'mental-wellbeing/actions';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonClickSound from 'assets/sounds/button.mp3';

const BaseComponent = ({
  // eslint-disable-next-line no-shadow
  allQuestionMap, initialState, savedData, storeData, type, maxPage, sectionPage, previous, current, next }) => {
  const [form, setState] = useState({ ...initialState });
  const [currentPage, setCurrentPage] = useState(1);

  const [play] = useSound(ButtonClickSound);
  const [playNavChange] = useSound(PageChangeSound);
  const location = useLocation();
  const questionMap = allQuestionMap.filter((q) => q.currentPage === currentPage);

  useEffect(() => {
    setState((prevForm) => ({ ...prevForm, ...savedData }));
    setCurrentPage(savedData.currentPage || currentPage);
    // eslint-disable-next-line
	}, [location.pathname]);

  const isNextDisabled = () => {
    for (let i = 0; i < questionMap.length; i++) {
      if (!form[questionMap[i].name]) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => function cleanup() {
    if (!isNextDisabled()) {
      const data = { ...form };
      data.actionType = type;
      if (currentPage === maxPage) {
        data.currentPage = currentPage;
        data.impact = calculateImpact(data, allQuestionMap);
        // eslint-disable-next-line no-console
        console.log(`${type}:  ${data.impact}`);
      }
      storeData(data);
    }
  }
  // eslint-disable-next-line
	, [form])

  const handleOnChange = (e) => {
    play();
    setState({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleNavChange = (pg) => {
    setCurrentPage(currentPage + pg);
    playNavChange();
  };

  const disableNext = isNextDisabled();
  const disableHeaderNext = currentPage === maxPage ? disableNext : true;

  return (
    <>
      <Header disableNext={disableHeaderNext} />
      <main className="main">
        <div className="four-opt-wrapper default-wrapper">
          {questionMap.map((q) => (
            <Question
              text={q.text}
              name={q.name}
              value={String(form[q.name])}
              key={q.name}
              clickHandler={(e) => handleOnChange(e)}
            />
          ))}
        </div>
      </main>

      <BottomNavButtons
        pageNum={sectionPage}
        isNextDisabled={disableNext}
        previous={`/mental-well-being/${currentPage === 1 ? previous : current}`}
        next={`/mental-well-being/${currentPage === maxPage ? next : current}`}
        onBackClick={() => handleNavChange(-1)}
        onNextClick={() => handleNavChange(1)}
      />
    </>
  );
};

export default connect(null, { storeData })(withRouter(BaseComponent));
