import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useSound from 'use-sound';
import i18next from 'i18next';
import React from 'react';

import PageChangeSound from 'assets/sounds/swoosh.mp3';

const AnswerSection = ({ answer, nextLink, current }) => {
  const answerMessage = answer === 'correct' ? `erg${current}CorrectText` : 'incorrectMsg';
  const [play] = useSound(PageChangeSound);
  const langDir = i18next.dir();
  const { t } = useTranslation();

  return (
    <div className={`select-footnote ${answer}`}>
      <h3>
        <span dir={langDir} className="title-case">{t(answer)}!</span>
        <small dir={langDir} className={answer === 'wrong' ? 'center' : langDir}>
          { t(answerMessage) }
        </small>
      </h3>
      {
        answer === 'correct'
        && (
          <div className="btn-center">
            <Link className="btn btn-primary" onClick={() => play()} to={`/ergonomics/${nextLink}`}>
              {t('next')}
            </Link>
          </div>
        )
      }
    </div>
  );
};

export default AnswerSection;
