import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';

import 'i18nextConf';
import App from 'App';
import store from 'store';
import reportWebVitals from 'reportWebVitals';

import 'assets/sass/style.scss';

ReactDOM.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById('root'),
);
// document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
