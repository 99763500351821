import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import React from 'react';

const BottomNavButtons = (props) => {
  const { t } = useTranslation();
  const { onBackClick, previous, next, onNextClick, isNextDisabled, pageNum } = props;
  return (
    <div className="footer-buttons">
      <Link onClick={onBackClick} to={previous} className="btn btn-primary">
        {t('back')}
      </Link>

      <Link
        to={next}
        onClick={onNextClick}
        className={`btn btn-primary ${isNextDisabled && 'disabled disabled-link'}`}
      >
        {t('next')}
      </Link>
      <strong className="step-count">
        {pageNum}/8
      </strong>
    </div>
  );
};

export default BottomNavButtons;
