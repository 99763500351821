import React from 'react';

import 'components/button/index.scss';

const Button = ({ label, ...props }) => {
  const { type, disabled } = props;

  return (
    <button className={`btn ${type}`} type="button" disabled={disabled}>{label}</button>
  );
};

Button.defaultProps = {
  label: 'Button',
  type: '',
  disabled: false,
};

export default Button;
