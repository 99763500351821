import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route, withRouter,
} from 'react-router-dom';
import 'components/button';

import NotFound from 'common/pages/404';
import Work from 'mental-wellbeing/pages/work/index';
import Start from 'mental-wellbeing/pages/start/index';
import Score from 'mental-wellbeing/pages/score/index';
import GetStarted from 'common/pages/get-started/index';
import Action from 'mental-wellbeing/pages/action/index';
import Autonomy from 'mental-wellbeing/pages/autonomy/index';
import Belonging from 'mental-wellbeing/pages/belonging/index';
import Competence from 'mental-wellbeing/pages/competence/index';
import Motivation from 'mental-wellbeing/pages/motivation/index';

import Logo from 'mental-wellbeing/assets/images/mental-health-logo.svg';

const MentalWellBeing = () => (
  <Router>
    <Switch>
      <Route path="/mental-well-being" exact>
        <GetStarted
          title="Mental Wellbeing"
          next="/mental-well-being/start"
          logo={Logo}
        />
      </Route>
      <Route>
        <div className="wrapper">
          <Switch>
            <Route path="/mental-well-being/start" component={Start} />
            <Route path="/mental-well-being/work" component={Work} />
            <Route path="/mental-well-being/autonomy" component={Autonomy} />
            <Route path="/mental-well-being/motivation" component={Motivation} />
            <Route path="/mental-well-being/belonging" component={Belonging} />
            <Route path="/mental-well-being/competence" component={Competence} />
            <Route path="/mental-well-being/score" component={Score} />
            <Route path="/mental-well-being/action" component={Action} />
            <Route path="/mental-well-being/*" component={NotFound} />
          </Switch>
        </div>
      </Route>
    </Switch>
  </Router>
);

export default withRouter(MentalWellBeing);
