import React from 'react';
import { withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import 'common/components/HeaderNavigation/index.scss';
import NavigationLink from 'common/components/HeaderNavigation/NavigationLink';

import ImgTransportationHover from 'ef-calculator/assets/images/transportation-colored.svg';
import ImgElectricityHover from 'ef-calculator/assets/images/electricity-colored.svg';
import ImgFootprintHover from 'ef-calculator/assets/images/footprint-colored.svg';
import ImgTransportation from 'ef-calculator/assets/images/transportation.svg';
import ImgPlasticHover from 'ef-calculator/assets/images/plastic-colored.svg';
import ImgActionHover from 'ef-calculator/assets/images/action-colored.svg';
import ImgStartHover from 'ef-calculator/assets/images/start-colored.svg';
import ImgPaperHover from 'ef-calculator/assets/images/paper-colored.svg';
import ImgWaterHover from 'ef-calculator/assets/images/water-colored.svg';
import ImgElectricity from 'ef-calculator/assets/images/electricity.svg';
import ImgFootprint from 'ef-calculator/assets/images/footprint.svg';
import ImgPlastic from 'ef-calculator/assets/images/plastic.svg';
import ImgAction from 'ef-calculator/assets/images/action.svg';
import ImgWater from 'ef-calculator/assets/images/water.svg';
import ImgPaper from 'ef-calculator/assets/images/paper.svg';
import ImgStart from 'ef-calculator/assets/images/start.svg';

const Header = (props) => {
  const start = '/ef-calculator/start';
  const transportation = '/ef-calculator/transportation';
  const paper = '/ef-calculator/paper';
  const electricity = '/ef-calculator/electricity';
  const electricityAppliances = '/ef-calculator/electricity-appliances';
  const plastic = '/ef-calculator/plastic';
  const water = '/ef-calculator/water';
  const waterBreak = '/ef-calculator/water-break';
  const waterWaste = '/ef-calculator/water-waste';
  const footprint = '/ef-calculator/footprint';
  const takeAction = '/ef-calculator/take-action';

  const pathMap = {
    [start]: 1,
    [transportation]: 2,
    [paper]: 3,
    [electricity]: 4,
    [electricityAppliances]: 4,
    [plastic]: 5,
    [water]: 6,
    [waterBreak]: 6,
    [waterWaste]: 6,
    [footprint]: 7,
    [takeAction]: 8,
  };

  const pathName = props.location.pathname;
  const { disableNext } = props;

  const isTransportDisabled = (disableNext === false && pathName === start)
    ? false : (pathMap[pathName] < pathMap[transportation]);

  const isPaperDisabled = (disableNext === false && pathName === transportation)
    ? false : (pathMap[pathName] < pathMap[paper]);

  const isElectricityDisabled = (disableNext === false && pathName === paper)
    ? false : (pathMap[pathName] < pathMap[electricity]);

  const isPlasticDisabled = (disableNext === false && pathName === electricityAppliances)
    ? false : (pathMap[pathName] < pathMap[plastic]);

  const isWaterDisabled = (disableNext === false && pathName === plastic)
    ? false : (pathMap[pathName] < pathMap[water]);

  const isFootprintDisabled = (disableNext === false && pathName === waterWaste)
    ? false : (pathMap[pathName] < pathMap[footprint]);

  const isTakeActionDisabled = pathName !== footprint;
  const { t } = useTranslation();

  return (
    <header className="header">
      <nav className="navigation">
        <ul>
          <li>
            <NavigationLink
              tabPath={start}
              imgStart={ImgStart}
              title={t('start')}
              imgStartHover={ImgStartHover}
              active={pathName === start}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={transportation}
              imgStart={ImgTransportation}
              title={t('transport')}
              active={pathName === transportation}
              imgStartHover={ImgTransportationHover}
              disabled={isTransportDisabled}
            />

          </li>
          <li>
            <NavigationLink
              tabPath={paper}
              imgStart={ImgPaper}
              imgStartHover={ImgPaperHover}
              active={pathName === paper}
              title={t('paper')}
              disabled={isPaperDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={electricity}
              imgStart={ImgElectricity}
              title={t('electricity')}
              active={pathName === electricity || pathName === electricityAppliances}
              imgStartHover={ImgElectricityHover}
              disabled={isElectricityDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={plastic}
              imgStart={ImgPlastic}
              imgStartHover={ImgPlasticHover}
              active={pathName === plastic}
              title={t('plastic')}
              disabled={isPlasticDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={water}
              imgStart={ImgWater}
              imgStartHover={ImgWaterHover}
              active={pathName === water || pathName === waterBreak
                                        || pathName === waterWaste}
              title={t('water')}
              disabled={isWaterDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={footprint}
              imgStart={ImgFootprint}
              title={t('yourFootprint')}
              imgStartHover={ImgFootprintHover}
              active={pathName === footprint}
              disabled={isFootprintDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={takeAction}
              imgStart={ImgAction}
              imgStartHover={ImgActionHover}
              active={pathName === takeAction}
              title={t('takeAction')}
              disabled={isTakeActionDisabled}
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default withRouter(Header);
