export const START = 'START';
export const TRANSPORTATION = 'TRANSPORTATION';
export const PAPER = 'PAPER';
export const ELECTRICITY = 'ELECTRICITY';
export const PLASTIC = 'PLASTIC';
export const WATER = 'WATER';
export const WATER_WASTE = 'WATER_WASTE';
export const FOOTPRINT = 'FOOTPRINT';
export const WATER_GRAPH = 'WATER_GRAPH';

export function storeData(data) {
  return {
    type: data.actionType,
    payload: data,
  };
}
