import React from 'react';
import { withRouter } from 'react-router-dom';

import NavigationLink from 'common/components/HeaderNavigation/NavigationLink';

import 'common/components/HeaderNavigation/index.scss';
import ImgWork from 'mental-wellbeing/assets/images/work.svg';
import ImgStart from 'mental-wellbeing/assets/images/start.svg';
import ImgScore from 'mental-wellbeing/assets/images/score.svg';
import ImgAction from 'mental-wellbeing/assets/images/action.svg';
import ImgAutonomy from 'mental-wellbeing/assets/images/autonomy.svg';
import ImgBelonging from 'mental-wellbeing/assets/images/belonging.svg';
import ImgCompetence from 'mental-wellbeing/assets/images/competence.svg';
import ImgMotivation from 'mental-wellbeing/assets/images/motivation.svg';
import ImgWorkHover from 'mental-wellbeing/assets/images/work-colored.svg';
import ImgStartHover from 'mental-wellbeing/assets/images/start-colored.svg';
import ImgScoreHover from 'mental-wellbeing/assets/images/score-colored.svg';
import ImgActionHover from 'mental-wellbeing/assets/images/action-colored.svg';
import ImgAutonomyHover from 'mental-wellbeing/assets/images/autonomy-colored.svg';
import ImgBelongingHover from 'mental-wellbeing/assets/images/belonging-colored.svg';
import ImgMotivationHover from 'mental-wellbeing/assets/images/motivation-colored.svg';
import ImgCompetenceHover from 'mental-wellbeing/assets/images/competence-colored.svg';

const Header = (props) => {
  const start = '/mental-well-being/start';
  const work = '/mental-well-being/work';
  const autonomy = '/mental-well-being/autonomy';
  const motivation = '/mental-well-being/motivation';
  const belonging = '/mental-well-being/belonging';
  const competence = '/mental-well-being/competence';
  const score = '/mental-well-being/score';
  const takeAction = '/mental-well-being/action';

  const pathMap = {
    [start]: 1,
    [work]: 2,
    [autonomy]: 3,
    [motivation]: 4,
    [belonging]: 5,
    [competence]: 6,
    [score]: 7,
    [takeAction]: 8,
  };

  const pathName = props.location.pathname;
  const { disableNext } = props;

  const isWorkDisabled = (disableNext === false && pathName === start)
    ? false : (pathMap[pathName] < pathMap[work]);

  const isAutonomyDisabled = (disableNext === false && pathName === work)
    ? false : (pathMap[pathName] < pathMap[autonomy]);

  const isMotivationDisabled = (disableNext === false && pathName === autonomy)
    ? false : (pathMap[pathName] < pathMap[motivation]);

  const isBelongingDisabled = (disableNext === false && pathName === motivation)
    ? false : (pathMap[pathName] < pathMap[belonging]);

  const isCompetenceDisabled = (disableNext === false && pathName === belonging)
    ? false : (pathMap[pathName] < pathMap[competence]);

  const isScoreDisabled = (disableNext === false && pathName === competence)
    ? false : (pathMap[pathName] < pathMap[score]);

  const isTakeActionDisabled = pathName !== score;

  return (
    <header className="header">
      <nav className="navigation">
        <ul>
          <li>
            <NavigationLink
              tabPath={start}
              imgStart={ImgStart}
              title="Start"
              imgStartHover={ImgStartHover}
              active={pathName === start}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={work}
              imgStart={ImgWork}
              title="Work"
              active={pathName === work}
              imgStartHover={ImgWorkHover}
              disabled={isWorkDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={autonomy}
              imgStart={ImgAutonomy}
              imgStartHover={ImgAutonomyHover}
              active={pathName === autonomy}
              title="Autonomy"
              disabled={isAutonomyDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={motivation}
              imgStart={ImgMotivation}
              title="Motivation"
              active={pathName === motivation}
              imgStartHover={ImgMotivationHover}
              disabled={isMotivationDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={belonging}
              imgStart={ImgBelonging}
              active={pathName === belonging}
              imgStartHover={ImgBelongingHover}
              onClickCallback={props.handleNavChange}
              title="Belonging"
              disabled={isBelongingDisabled}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={competence}
              imgStart={ImgCompetence}
              active={pathName === competence}
              imgStartHover={ImgCompetenceHover}
              title="Competence"
              disabled={isCompetenceDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={score}
              imgStart={ImgScore}
              title="Your Score"
              imgStartHover={ImgScoreHover}
              active={pathName === score}
              disabled={isScoreDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
          <li>
            <NavigationLink
              tabPath={takeAction}
              imgStart={ImgAction}
              imgStartHover={ImgActionHover}
              active={pathName === takeAction}
              title="Take Action"
              disabled={isTakeActionDisabled}
              onClickCallback={props.handleNavChange}
            />
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default withRouter(Header);
