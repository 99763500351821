import React from 'react';

import PaginationDots from 'common/components/PaginationDots';
import AnswerSection from 'go-phishing/components/AnswerSection';
import FeedbackButtons from 'go-phishing/components/FeedbackButtons';

const InfoSection = ({ answer, correctAnswer, feedbackText, heading, helpText, handleResponse, next, currentPage }) => {
  const boolAnswer = answer === correctAnswer ? 'correct' : 'wrong';

  return (
    <div className="col-info">
      <h2 className="heading-h2">{heading}</h2>
      <p className="help-text">{helpText}</p>

      <FeedbackButtons
        answer={answer}
        correctAnswer={correctAnswer}
        responseHandler={(ans) => handleResponse(ans)}
      />
      {
        answer !== ''
        && (
          <AnswerSection
            answer={boolAnswer}
            nextPath={next}
            feedbackText={feedbackText}
          />
        )
      }
      <PaginationDots currentPage={currentPage} total={9} />
    </div>
  );
};

export default InfoSection;
