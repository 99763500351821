/* eslint-disable no-case-declarations,no-nested-ternary,no-restricted-syntax */
import React, { useReducer, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import useSound from 'use-sound';

import Header from 'ef-calculator/components/header';
import { PLASTIC, storeData } from 'ef-calculator/actions';
import BottomNavButtons from 'common/components/BottomNavButtons';
import NumberInputButtons from 'common/components/NumberInputButtonsField';
import { calculatePlasticCarbonEmission } from 'ef-calculator/utils/helper';

import PageChangeSound from 'assets/sounds/swoosh.mp3';
import ButtonSound from 'assets/sounds/button.mp3';

const initialState = {
  straws: 0,
  petBottles: 0,
  plasticBags: 0,
  foodWrappers: 0,
  taPlasticBox: 0,
  taPlasticCup: 0,
  disposableCutlery: 0,
};

const reducer = (state, action) => {
  let key = null;
  switch (action.type) {
    case 'Decrement':
      return { ...state, [action.key]: state[action.key] > 0 ? parseInt(state[action.key]) - 1 : state[action.key] };
    case 'Increment':
      key = action.key;
      return { ...state, [key]: parseInt(state[key]) + 1 > 99 ? parseInt(state[key]) : parseInt(state[key]) + 1 };
    case 'onChange':
      key = action.e.target.name;
      const { value } = action.e.target;
      return { ...state, [key]: parseInt((action.e.target.validity.valid) ? (value === '' ? 0 : value) : state[key]) };

    case 'update':
      return { ...state, ...action.payload };
    default:
      throw new Error('Unexpected action');
  }
};

const Plastic = (props) => {
  const { t } = useTranslation();
  const isNextDisabled = false;
  const [state, dispatch] = useReducer(reducer, initialState);
  const componentWillUnmount = useRef(false);

  const [playButtonSound] = useSound(ButtonSound);
  const [playNavChange] = useSound(PageChangeSound);

  useEffect(() => () => {
    componentWillUnmount.current = true;
  }, []);

  useEffect(() => () => {
    if (componentWillUnmount.current) {
      const impact = calculatePlasticCarbonEmission(state);
      // eslint-disable-next-line no-console
      console.log(`plastic:  ${impact}`); // temp
      props.storeData({ ...state, impact, actionType: PLASTIC });
    }
  }
  // eslint-disable-next-line
	, [state])

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      dispatch({ type: 'update', payload: props.plasticData });
    }
    return () => {
      mounted = false;
    };
    // eslint-disable-next-line
	}, [])

  const renderItemsInputs = () => {
    const fields = [];
    const applianceMap = {
      petBottles: t('plasticPetBottles'),
      plasticBags: t('plasticPBags'),
      foodWrappers: t('plasticWrapping'),
      taPlasticBox: t('plasticTAPB'),
      taPlasticCup: t('plasticTAPC'),
      straws: t('plasticStraw'),
      disposableCutlery: t('plasticDisposableCutlery'),
    };
    for (const [name, text] of Object.entries(applianceMap)) {
      fields.push(
        <NumberInputButtons
          key={name}
          value={state[name]}
          name={name}
          text={text}
          handleOnChange={(e) => dispatch({ type: 'onChange', e })}
          handleDecrement={() => { playButtonSound(); dispatch({ type: 'Decrement', key: name }); }}
          handleIncrement={() => { playButtonSound(); dispatch({ type: 'Increment', key: name }); }}
        />,
      );
    }
    return fields;
  };

  return (
    <>
      <Header disableNext={isNextDisabled} />

      <main className="main">
        <div className="default-wrapper plastic-page">
          <div className="form-row">
            <h2>{t('plasticItemUseQT')}</h2>
            <ul className="buttons-list">
              {renderItemsInputs()}
            </ul>
          </div>
        </div>
      </main>

      <BottomNavButtons
        pageNum="5"
        next="/ef-calculator/water"
        isNextDisabled={isNextDisabled}
        onBackClick={() => { playNavChange(); }}
        onNextClick={() => { playNavChange(); }}
        previous="/ef-calculator/electricity-appliances"
      />

    </>
  );
};

function mapStateToProp({ efCalculatorReducer }) {
  return ({ plasticData: efCalculatorReducer.plastic });
}

export default connect(mapStateToProp, { storeData })(withRouter(Plastic));
