import { withRouter } from 'react-router-dom';
import React from 'react';

import BaseComponent from 'ergonomics/components/BaseComponent';

import User from 'ergonomics/assets/images/user-spine.svg';
import WorkTable from 'ergonomics/assets/images/table-work.svg';
import UserCorrectImg from 'ergonomics/assets/images/user-arms.svg';

const BackPain = () => {
  const correctAssetsMap = {
    userCorrectImg: UserCorrectImg,
    userClassName: 'user-arms-screen',
  };

  return (
    <>
      <div className="info-box-wrap">
        <BaseComponent
          afterClicked="back-pain-clicked"
          beforeClicked="back-pain"
          userClassName="user-position"
          pcClassName="pc-position"
          current="BackPain"
          UserImg={User}
          CorrectAssetsMap={correctAssetsMap}
          PCImg={WorkTable}
          currentPage={1}
          nextLink="arms"
        />
      </div>
    </>
  );
};

export default withRouter(BackPain);
